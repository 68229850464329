import { useTranslation } from 'next-i18next'

import { FormControl, MenuItem, Select } from '@mui/material'
import { keyBy } from 'lodash'
import { useNormalizedSortBy } from '@/hooks/useNormalizedSortBy'
import { useDeepCompareMemo } from 'use-deep-compare'

import { useAnalyticsTracking } from '@/hooks'

export const SortByDropdown = ({ dataCyPrefix }: { dataCyPrefix: string }) => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.filters',
  })
  const {
    currentRefinement,
    options,
    refine,
    initialIndex,
    originalItems,
    analyticsEventNames,
  } = useNormalizedSortBy()

  const optionsMap = useDeepCompareMemo(() => keyBy(options, 'value'), [options])

  const { trackFilterEvent } = useAnalyticsTracking()

  return (
    <FormControl variant="standard" data-cy={`${dataCyPrefix}-SortByDropdown`}>
      <Select
        variant="outlined"
        defaultValue={initialIndex}
        onChange={(e) => {
          const selectedValue = e.target.value
          trackFilterEvent('assetSorting', analyticsEventNames[selectedValue])
          refine(selectedValue)
        }}
        value={currentRefinement}
        renderValue={(value) => `${t('sort-by-label')}: ${optionsMap[value]?.label}`}
        inputProps={{
          'aria-label': t('sort-by-label'),
          'aria-labelledby': 'sorting-label',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-cy={`${dataCyPrefix}-SortByDropdown-MenuItem-${originalItems[index].label}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {/* Cannot directly add `aria-label` to HTML-native with MUI somehow, this is a workaround */}
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      <label id="sorting-label" style={{ display: 'none', maxHeight: '0px' }}>
        {t('sort-by-label')}
      </label>
    </FormControl>
  )
}
