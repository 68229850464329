import { useSearchBox } from 'react-instantsearch-core'

import { debounce } from 'lodash'

const debounceQuery = debounce((query, refine) => {
  refine(query)
}, 500)

export const useSearchBoxWithDebounce: typeof useSearchBox = (
  props?,
  additionalWidgetProperties?,
) => {
  const searchBox = useSearchBox(
    { ...props, queryHook: debounceQuery },
    additionalWidgetProperties,
  )

  return searchBox
}
