import logger from '@knauf-group/ct-shared-nextjs/logger'
import type { SearchClient, SearchIndex } from 'algoliasearch'
import algoliasearch from 'algoliasearch'

import { ALGOLIA_INDEX_PREFIX } from '@/constants'
import { getEnv } from '@/envs'

const { ALGOLIA_BROWSE_KEY = '' } = process.env

export const browseClient = algoliasearch(
  getEnv('NEXT_PUBLIC_ALGOLIA_APP_ID'),
  ALGOLIA_BROWSE_KEY,
)

export const getAlgoliaIndexName = (countryCode: string) => {
  return `${ALGOLIA_INDEX_PREFIX}_${countryCode}`
}

export const getAlgoliaIndex = async (
  client: SearchClient,
  indexName: string,
): Promise<SearchIndex | null> => {
  const index = client.initIndex(indexName)

  if (!index || !(await index.exists())) {
    logger.error(`Error while getting Algolia index ${indexName}`)
    return null
  }

  return index
}
