import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { Button, Stack, Tooltip } from '@mui/material'

const EXTERNAL_GYPSUM_URL = 'https://pd.knauf.de/start.php?frm_hersteller=1001'

const EXTERNAL_FIREPROOFS_URL =
  'https://tools.knauf.fr/documentation/bibliotheque-technique/type-document/resistance'

const EXTERNAL_ACOUSTICPROOF_URL =
  'https://tools.knauf.fr/documentation/bibliotheque-technique/rapport-dessai?document_type%5B0%5D=37039'

const EXTERNAL_INSULATION_URL_DEFAULT = 'https://www.knaufinsulation.com/iframe-cprdop'
const EXTERNAL_INSULATION_URLS: { [key: string]: string } = {
  'en-AU':
    'https://knauf.com/en-AU/knauf-insulation/competencies/sustainability/environmental-product-declarations',
  'en-NZ': 'https://knauf.com/en-NZ/knauf-insulation/competencies/sustainability/epds',
  'ja-JP': 'https://knauf.com/ja-JP/knauf-insulation/sustainability/epds',
  'en-MY':
    'https://knauf.com/en-MY/knauf-insulation/sustainability/environmental-product-declarations',
  'en-SG':
    'https://knauf.com/en-SG/knauf-insulation/sustainability/environmental-product-declarations',
  'ko-KR': 'https://knauf.com/ko-KR/knauf-insulation/sustainability/globalepd',
  'en-TH':
    'https://knauf.com/en-TH/knauf-insulation/sustainability/environmental-product-declarations',
  'th-TH':
    'https://knauf.com/th-TH/knauf-insulation/sustainability/environmental-product-declarations',
}

const EXTERNAL_BAUPRODUKTE_URL = 'https://pd.knauf.de/start.php?frm_hersteller=1003'

type ExternalFileButtonProps = {
  buttonText: string
  tooltipText: string
  url: string
  dataCy: string
  onClick?: () => void
}
const ExternalFileButton = ({
  buttonText,
  tooltipText,
  url,
  dataCy,
  onClick = undefined,
}: ExternalFileButtonProps) => {
  return (
    <Tooltip
      title={tooltipText}
      slotProps={{ tooltip: { sx: { fontSize: 13, lineHeight: 1.7 } } }}
      arrow
    >
      <Button target="_blank" href={url} variant="outlined" data-cy={dataCy} onClick={onClick}>
        {buttonText}
      </Button>
    </Tooltip>
  )
}

export const ExternalFilesButtons = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  const { locale } = useRouter()

  const showGypsumButton = locale === 'de-DE'
  const showBauprodukteButton = locale === 'de-DE'
  const showButtonsForFrenchLocale = locale === 'fr-FR'

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 2, sm: 3 }} flexWrap="wrap">
      {showGypsumButton && (
        <ExternalFileButton
          buttonText={t('buttons.externalGypsumFiles')}
          url={EXTERNAL_GYPSUM_URL}
          tooltipText={t('tooltipgypsum')}
          dataCy="gypsum-files-button"
        />
      )}
      {showButtonsForFrenchLocale && (
        <ExternalFileButton
          buttonText={t('buttons.externalFireProofFiles')}
          url={EXTERNAL_FIREPROOFS_URL}
          tooltipText={t('tooltipfireproof')}
          dataCy="fireproof-files-button"
        />
      )}
      <ExternalFileButton
        buttonText={t('buttons.externalFiles')}
        url={EXTERNAL_INSULATION_URLS[locale] || EXTERNAL_INSULATION_URL_DEFAULT}
        tooltipText={t('tooltip')}
        dataCy="external-files-button"
      />
      {showBauprodukteButton && (
        <ExternalFileButton
          buttonText={t('buttons.externalBauprodukteFiles')}
          url={EXTERNAL_BAUPRODUKTE_URL}
          tooltipText={t('tooltipbauprodukte')}
          dataCy="bauprodukte-files-button"
        />
      )}
      {showButtonsForFrenchLocale && (
        <ExternalFileButton
          buttonText={t('buttons.externalAcousticProofFiles')}
          url={EXTERNAL_ACOUSTICPROOF_URL}
          tooltipText={t('tooltipacoustics')}
          dataCy="acoustics-files-button"
        />
      )}
    </Stack>
  )
}
