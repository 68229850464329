import { capitalize } from 'lodash'

import { languageAndLocaleMapping } from './languageAndLocaleMapping'
import { languageNameTranslations } from './languageNameTranslations'

export const languageCodeRegex = /^[a-zA-Z]{2,3}$/

export const inferLanguageLabelByLanguageCode = (targetLanguageCode: string) => {
  const targetLanguageCodeToUse = languageAndLocaleMapping[targetLanguageCode]

  return (languageLabel: string) => {
    if (!languageLabel.match(languageCodeRegex)) return languageLabel

    if (targetLanguageCodeToUse in languageNameTranslations) {
      return (
        // @ts-expect-error TODO improve typing
        capitalize(languageNameTranslations[targetLanguageCodeToUse][languageLabel]) ??
        languageLabel
      )
    }

    const languageNames = new Intl.DisplayNames(targetLanguageCodeToUse, { type: 'language' })

    return capitalize(languageNames.of(languageLabel)) || languageLabel
  }
}
