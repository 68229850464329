import { useTranslation } from 'next-i18next'

import Searchbar, {
  validateSearchQuery,
} from '@knauf-group/ct-designs/components/core/Searchbar/Searchbar'
import { Box } from '@mui/material'
import { useSearchBoxWithDebounce } from '@/hooks/useSearchBoxWithDebounce'

import { useSearchContext } from '@/contexts/SearchContext/SearchContext'

export const SearchBox = ({ dataCy = 'Search-TextField' }: { dataCy?: string }) => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  const { refine } = useSearchBoxWithDebounce()
  const { internalQuery, setInternalQuery } = useSearchContext()

  const handleOnClear = () => {
    setInternalQuery('')
    refine('')
  }

  const handleQueryChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    const previousInternalQuery = internalQuery

    setInternalQuery(value)

    // if the user cleared the query manually we need to handle it as well
    if (value.length === 0) {
      handleOnClear()
      return
    }

    const valueTrimmed = value.trim()

    if (validateSearchQuery(value) && previousInternalQuery !== valueTrimmed) {
      refine(valueTrimmed)
    }
  }

  return (
    <Box sx={{ minHeight: '20px', width: '100%' }}>
      <Searchbar
        placeholder={t('search')}
        value={internalQuery}
        onChange={handleQueryChange}
        onClear={handleOnClear}
        fullWidth
        data-cy={dataCy}
      />
    </Box>
  )
}
