import { useCurrentRefinements } from 'react-instantsearch-core'

import type { CurrentRefinementsConnectorParamsItem } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { pickBy } from 'lodash'
import { getLanguageNameFromCode } from '@/utils/getLanguageName'
import { ALGOLIA_FACETS, HIERARCHICAL_LEVELS_SEPARATOR } from '@/utils/plain/algoliaConstants'
import { isNotEmpty } from '@/utils/plain/isEmpty'

import { ANALYTICS_STRING_SEPARATOR } from '@/constants'
import type { SelectedAsset } from '@/types'

import { useAlgoliaCategoryFacet } from '../useAlgoliaCategoryFacet'

export const defaultValues = {
  page_category: 'download_center',
  page_brand: 'no_brand_selected',
  page_product_category: 'no_product_category_selected',
}

function addCustomPropertyFromItem(
  items: CurrentRefinementsConnectorParamsItem[],
  attributeName: string,
) {
  // splitting because some facets have the facet name with the language, e.g. "storeAvailability.de". To make this standard, we only use the general name
  const attributeItem = items.find((i) => i.attribute.split('.')[0] === attributeName)

  if (attributeItem?.refinements.length) {
    return attributeItem.refinements.map((r) => r.value).join(ANALYTICS_STRING_SEPARATOR)
  }

  return ''
}

function getCategoryTypes(item?: CurrentRefinementsConnectorParamsItem) {
  if (item?.refinements.length) {
    const attributeValue = item.refinements.map((refinement) => refinement.value).join(';')

    if (attributeValue) {
      const [category, productType, productFamily] = String(attributeValue).split(
        HIERARCHICAL_LEVELS_SEPARATOR,
      )
      return { category, productType, productFamily }
    }
  }

  return {}
}

export function useGetCustomPropertiesFromAlgolia() {
  const categoriesAttributeKey = useAlgoliaCategoryFacet()
  const divisionFacetName = ALGOLIA_FACETS.division.facet

  const { items } = useCurrentRefinements({
    includedAttributes: [categoriesAttributeKey, divisionFacetName],
  })

  const categoryAttributeItem = items.find((i) => i.attribute === categoriesAttributeKey)
  const { category, productType, productFamily } = getCategoryTypes(categoryAttributeItem)

  const customProperties = {
    page_section: 'main',
    page_brand: addCustomPropertyFromItem(items, divisionFacetName),
    page_product_category: category,
    page_product_type: productType,
    page_product_family: productFamily,
  }

  return pickBy(customProperties, isNotEmpty)
}

export function useGetFilterProperties() {
  const divisionFacetName = ALGOLIA_FACETS.division.facet
  const documentTypesAttributeKey = ALGOLIA_FACETS.documentTypes.facet
  const languageAttributeKey = ALGOLIA_FACETS.language.facet
  const fieldsOfApplicationAttributeKey = ALGOLIA_FACETS.fieldsOfApplication.facet
  const storeAvailabilityAttributeKey = ALGOLIA_FACETS.storeAvailability.facet

  const { items } = useCurrentRefinements()

  const customProperties = {
    filter_document_type: addCustomPropertyFromItem(items, documentTypesAttributeKey),
    filter_division: addCustomPropertyFromItem(items, divisionFacetName),
    filter_application_scope: addCustomPropertyFromItem(items, fieldsOfApplicationAttributeKey),
    filter_availability: addCustomPropertyFromItem(items, storeAvailabilityAttributeKey),
    filter_language: getLanguageNameFromCode(
      addCustomPropertyFromItem(items, languageAttributeKey),
    ),
  }

  return pickBy(customProperties, isNotEmpty)
}

export function getAssetProperties(files: SelectedAsset[]) {
  return {
    asset_ids: files.map((file) => file.assetId).join(ANALYTICS_STRING_SEPARATOR),
    asset_names: files.map((file) => file.name).join(ANALYTICS_STRING_SEPARATOR),
    asset_languages: getLanguageNameFromCode(
      files.map((file) => file.language).join(ANALYTICS_STRING_SEPARATOR),
    ),
    asset_download_count: String(files.length),
  }
}
