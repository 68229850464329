import { ANALYTICS_STRING_SEPARATOR } from '@/constants'

import { languageCodeRegex } from './plain/inferLanguageLabelByLanguageCode'

export const getLanguageNameFromCode = (codes: string | undefined) => {
  if (!codes) return ''

  const languageNames = new Intl.DisplayNames(['en'], {
    type: 'language',
  })

  const codeNames: string[] = []

  codes.split(ANALYTICS_STRING_SEPARATOR).forEach((code) => {
    if (!code.match(languageCodeRegex)) return

    const codeName = languageNames.of(code)?.toLowerCase()

    if (codeName) {
      codeNames.push(codeName)
    }
  })

  return codeNames.join(ANALYTICS_STRING_SEPARATOR)
}
