import { useRouter } from 'next/router'

import { ALGOLIA_FACETS } from '@/utils/plain/algoliaConstants'

import { getLanguageAndCountry } from '@/utils'

export const useAlgoliaCategoryFacet = () => {
  const router = useRouter()
  const { locale } = router
  const { languageCode } = getLanguageAndCountry(locale)

  return `${ALGOLIA_FACETS.productCategories.facet}.${languageCode}`
}
