import { type PropsWithChildren, useState } from 'react'
import { useCurrentRefinements } from 'react-instantsearch-core'

import { useDeepCompareMemo } from 'use-deep-compare'

import type { FilterSelectionState } from '@/contexts/FilterSelectionContext/FilterSelectionContext'
import { FilterSelectionContext } from '@/contexts/FilterSelectionContext/FilterSelectionContext'

export const FilterSelectionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { items: currentRefinements } = useCurrentRefinements()
  const activeRefinements = currentRefinements.flatMap((refinement) => {
    const { attribute } = refinement
    return refinement.refinements.map((item) => ({
      value: String(item.value),
      label: item.label,
      isRefined: true,
      count: 0,
      attribute,
    }))
  })

  const initialFilters: FilterSelectionState = activeRefinements.reduce((acc, refinement) => {
    acc[refinement.attribute] = [String(refinement.value)]
    return acc
  }, {} as FilterSelectionState)

  const [selectedFilters, setSelectedFilters] = useState<FilterSelectionState>(initialFilters)

  const addFilter = (category: string, item: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: [...(prev[category] || []), item].filter(
        (value, index, self) => self.indexOf(value) === index,
      ),
    }))
  }

  const removeFilter = (category: string, item: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: (prev[category] || []).filter((value) => value !== item),
    }))
  }

  const clearFilters = (category?: string) => {
    if (category) {
      setSelectedFilters(() => {
        const updatedFilters = { ...selectedFilters }
        delete updatedFilters[category]
        return updatedFilters
      })
    } else {
      setSelectedFilters({})
    }
  }

  return (
    <FilterSelectionContext.Provider
      value={useDeepCompareMemo(
        () => ({ selectedFilters, addFilter, removeFilter, clearFilters, setSelectedFilters }),
        [selectedFilters],
      )}
    >
      {children}
    </FilterSelectionContext.Provider>
  )
}
