import type { RefinementListProps } from 'react-instantsearch'
import { useRefinementList } from 'react-instantsearch-core'
import { useRouter } from 'next/router'

import { getLanguageAndCountry } from '@/utils'

import { ALGOLIA_FACETS_MAP, maxFacetHits } from '../utils/plain/algoliaConstants'

const VirtualRefinementList = (props: RefinementListProps) => {
  useRefinementList(props)
  return null
}

// This temporary hack allows to correctly populate the facets during SSR
// If removed useCurrentRefinements state will be inconsistent with the filters in the first render
// Triggering the useRefinementList before using useCurrentRefinements fix this
// see: https://github.com/algolia/instantsearch/issues/5571
export const VirtualRefinementsList = () => {
  const { locale } = useRouter()
  const { languageCode } = getLanguageAndCountry(locale)

  return (
    <>
      {Object.entries(ALGOLIA_FACETS_MAP.refinementList).map(([attribute, options]) => {
        const languageSuffixNeeded = 'hasLanguageSuffix' in options && options.hasLanguageSuffix
        const attributeToUse = languageSuffixNeeded ? `${attribute}.${languageCode}` : attribute

        return (
          <VirtualRefinementList
            key={attributeToUse}
            attribute={attributeToUse}
            limit={maxFacetHits}
          />
        )
      })}
    </>
  )
}
