import { useSortBy } from 'react-instantsearch-core'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import type { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy'
import { ALGOLIA_INDEX_NAME_SUFFICES } from '@/utils/plain/algoliaConstants'
import { isNotEmpty } from '@/utils/plain/isEmpty'
import { splitLocale } from '@/utils/plain/splitLocale'
import { getEnv } from '@/envs'

const generateAlgoliaIndexName = ({
  country,
  sortBy,
}: {
  country: string
  sortBy?: string
}) => {
  let indexName = `${getEnv('NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX')}_${country.toLowerCase()}`

  if (isNotEmpty(sortBy)) {
    indexName = `${indexName}_${sortBy}`
  }

  return indexName
}

const generateSortByItems = (
  locale: string,
): (SortByItem & { analyticsEventName: string })[] => {
  const { country } = splitLocale(locale)

  return ALGOLIA_INDEX_NAME_SUFFICES.map(
    ({ translationLabel, indexNameSuffix, analyticsEventName }) => ({
      label: translationLabel,
      value: generateAlgoliaIndexName({ country, sortBy: indexNameSuffix }),
      analyticsEventName,
    }),
  )
}

export const useNormalizedSortBy = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.filters.sort-by',
  })
  const items = generateSortByItems(locale)

  const analyticsEventNames = Object.fromEntries(
    items.map((item) => [item.value, item.analyticsEventName]),
  )
  const sortBy = useSortBy({
    items: items.map((item) => ({
      label: t(item.label),
      value: item.value,
    })),
  })

  return {
    ...sortBy,
    originalItems: items,
    analyticsEventNames,
  }
}
