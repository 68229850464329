// You can temporarily install `@formatjs/intl-displaynames`,
// and check the file `node_modules/@formatjs/intl-displaynames/locale-data/<your language code>`
// to see language name translations and more.

export const languageNameTranslations = {
  az: {
    aa: 'afar',
    ab: 'abxaz',
    ace: 'akin',
    ach: 'akoli',
    ada: 'adanqme',
    ady: 'adıgey',
    ae: 'avestan',
    af: 'afrikaans',
    afh: 'afrihili',
    agq: 'aqhem',
    ain: 'aynu',
    ak: 'akan',
    akk: 'akkad',
    ale: 'aleut',
    alt: 'cənubi altay',
    am: 'amhar',
    an: 'araqon',
    ang: 'qədim ingilis',
    ann: 'obolo',
    anp: 'angika',
    ar: 'ərəb',
    'ar-001': 'ərəb (Dünya)',
    arc: 'aramik',
    arn: 'mapuçe',
    arp: 'arapaho',
    ars: 'Nəcd ərəbcəsi',
    arw: 'aravak',
    as: 'assam',
    asa: 'asu',
    ast: 'asturiya',
    atj: 'Atikamek',
    av: 'avar',
    awa: 'avadhi',
    ay: 'aymara',
    az: 'azərbaycan',
    'az-Arab': 'cənubi azərbaycan',
    ba: 'başqırd',
    bal: 'baluc',
    ban: 'bali',
    bas: 'basa',
    be: 'belarus',
    bej: 'beja',
    bem: 'bemba',
    bez: 'bena',
    bg: 'bolqar',
    bgc: 'Haryanvi',
    bgn: 'qərbi bəluc',
    bho: 'bxoçpuri',
    bi: 'bislama',
    bik: 'bikol',
    bin: 'bini',
    bla: 'siksikə',
    blo: 'anii',
    bm: 'bambara',
    bn: 'benqal',
    bo: 'tibet',
    br: 'breton',
    bra: 'braj',
    brx: 'bodo',
    bs: 'bosniya',
    bua: 'buryat',
    bug: 'bugin',
    byn: 'blin',
    ca: 'katalan',
    cad: 'keddo',
    car: 'karib',
    cay: 'Kayuqa',
    cch: 'atsam',
    ccp: 'Çakma',
    ce: 'çeçen',
    ceb: 'sebuan',
    cgg: 'çiqa',
    ch: 'çamoro',
    chb: 'çibça',
    chg: 'çağatay',
    chk: 'çukiz',
    chm: 'mari',
    chn: 'çinuk ləhçəsi',
    cho: 'çoktau',
    chp: 'çipevyan',
    chr: 'çeroki',
    chy: 'çeyen',
    ckb: 'Mərkəzi kürdcə',
    clc: 'Çilotin',
    co: 'korsika',
    cop: 'kopt',
    cr: 'kri',
    crg: 'miçif',
    crh: 'krım türkcəsi',
    crj: 'cənub-şərqi kri',
    crk: 'ova kricəsi',
    crl: 'şimal-şəqri kri',
    crm: 'muz kri',
    crr: 'Karolina alonkincəsi',
    crs: 'Seyşel kreol fransızcası',
    cs: 'çex',
    csb: 'kaşubyan',
    csw: 'bataqlıq kricəsi',
    cu: 'slavyan',
    cv: 'çuvaş',
    cy: 'uels',
    da: 'danimarka',
    dak: 'dakota',
    dar: 'darqva',
    dav: 'taita',
    de: 'alman',
    'de-AT': 'alman (Avstriya)',
    'de-CH': 'alman (İsveçrə)',
    del: 'delaver',
    den: 'slavey',
    dgr: 'doqrib',
    din: 'dinka',
    dje: 'zarma',
    doi: 'doqri',
    dsb: 'aşağı sorb',
    dua: 'duala',
    dum: 'orta holland',
    dv: 'maldiv',
    dyo: 'diola',
    dyu: 'dyula',
    dz: 'dzonqxa',
    dzg: 'dazaqa',
    ebu: 'embu',
    ee: 'eve',
    efi: 'efik',
    egy: 'qədim misir',
    eka: 'ekacuk',
    el: 'yunan',
    elx: 'elamit',
    en: 'ingilis',
    'en-AU': 'ingilis (Avstraliya)',
    'en-CA': 'ingilis (Kanada)',
    'en-GB': 'ingilis (Birləşmiş Krallıq)',
    'en-US': 'ingilis (Amerika Birləşmiş Ştatları)',
    enm: 'orta ingilis',
    eo: 'esperanto',
    es: 'ispan',
    'es-419': 'ispan (Latın Amerikası)',
    'es-ES': 'ispan (İspaniya)',
    'es-MX': 'ispan (Meksika)',
    et: 'eston',
    eu: 'bask',
    ewo: 'evondo',
    fa: 'fars',
    'fa-AF': 'fars (Əfqanıstan)',
    fan: 'fang',
    fat: 'fanti',
    ff: 'fula',
    fi: 'fin',
    fil: 'filippin',
    fj: 'fici',
    fo: 'farer',
    fon: 'fon',
    fr: 'fransız',
    'fr-CA': 'fransız (Kanada)',
    'fr-CH': 'fransız (İsveçrə)',
    frc: 'Kacun fransızcası',
    frm: 'orta fransız',
    fro: 'qədim fransız',
    frr: 'şimali fris',
    fur: 'friul',
    fy: 'qərbi friz',
    ga: 'irland',
    gaa: 'qa',
    gag: 'qaqauz',
    gan: 'qan',
    gay: 'qayo',
    gba: 'qabaya',
    gd: 'Şotlandiya keltcəsi',
    gez: 'qez',
    gil: 'qilbert',
    gl: 'qalisiya',
    gmh: 'orta yüksək alman',
    gn: 'quarani',
    goh: 'qədim alman',
    gon: 'qondi',
    gor: 'qorontalo',
    got: 'qotika',
    grb: 'qrebo',
    grc: 'qədim yunan',
    gsw: 'İsveçrə almancası',
    gu: 'qucarat',
    guz: 'qusi',
    gv: 'manks',
    gwi: 'qviçin',
    ha: 'hausa',
    hai: 'hayda',
    hak: 'hakka',
    haw: 'havay',
    hax: 'cənubi haida',
    he: 'ivrit',
    hi: 'hind',
    'hi-Latn': 'Hindi (latın)',
    hil: 'hiliqaynon',
    hit: 'hittit',
    hmn: 'monq',
    ho: 'hiri motu',
    hr: 'xorvat',
    hsb: 'yuxarı sorb',
    hsn: 'syan',
    ht: 'haiti kreol',
    hu: 'macar',
    hup: 'hupa',
    hur: 'Halkomelem',
    hy: 'erməni',
    hz: 'herero',
    ia: 'interlinqua',
    iba: 'iban',
    ibb: 'ibibio',
    id: 'indoneziya',
    ie: 'interlinqve',
    ig: 'iqbo',
    ii: 'siçuan yi',
    ik: 'inupiaq',
    ikt: 'qərbi Kanada inuktitutu',
    ilo: 'iloko',
    inh: 'inquş',
    io: 'ido',
    is: 'island',
    it: 'italyan',
    iu: 'inuktitut',
    ja: 'yapon',
    jbo: 'loğban',
    jgo: 'nqomba',
    jmc: 'maçam',
    jpr: 'ivrit-fars',
    jrb: 'ivrit-ərəb',
    jv: 'yava',
    ka: 'gürcü',
    kaa: 'qaraqalpaq',
    kab: 'kabile',
    kac: 'kaçin',
    kaj: 'ju',
    kam: 'kamba',
    kaw: 'kavi',
    kbd: 'kabarda-çərkəz',
    kcg: 'tiyap',
    kde: 'makond',
    kea: 'kabuverdian',
    kfo: 'koro',
    kg: 'konqo',
    kgp: 'kaiqanq',
    kha: 'xazi',
    kho: 'xotan',
    khq: 'koyra çiini',
    ki: 'kikuyu',
    kj: 'kuanyama',
    kk: 'qazax',
    kkj: 'kako',
    kl: 'kalaallisut',
    kln: 'kalencin',
    km: 'kxmer',
    kmb: 'kimbundu',
    kn: 'kannada',
    ko: 'koreya',
    koi: 'komi-permyak',
    kok: 'konkani',
    kos: 'kosreyan',
    kpe: 'kpelle',
    kr: 'kanuri',
    krc: 'qaraçay-balkar',
    krl: 'karel',
    kru: 'kurux',
    ks: 'kəşmir',
    ksb: 'şambala',
    ksf: 'bafia',
    ksh: 'köln',
    ku: 'kürd',
    kum: 'kumık',
    kut: 'kutenay',
    kv: 'komi',
    kw: 'korn',
    kwk: 'Kvakvala',
    kxv: 'kuvi',
    ky: 'qırğız',
    la: 'latın',
    lad: 'sefard',
    lag: 'langi',
    lah: 'qərbi pəncab',
    lam: 'lamba',
    lb: 'lüksemburq',
    lez: 'ləzgi',
    lg: 'qanda',
    li: 'limburq',
    lij: 'liquriya dili',
    lil: 'Liluet',
    lkt: 'lakota',
    lmo: 'lombard dili',
    ln: 'linqala',
    lo: 'laos',
    lol: 'monqo',
    lou: 'Luiziana kreolu',
    loz: 'lozi',
    lrc: 'şimali luri',
    lsm: 'saamia',
    lt: 'litva',
    lu: 'luba-katanqa',
    lua: 'luba-lulua',
    lui: 'luyseno',
    lun: 'lunda',
    lus: 'mizo',
    luy: 'luyia',
    lv: 'latış',
    mad: 'maduriz',
    mag: 'maqahi',
    mai: 'maitili',
    mak: 'makasar',
    man: 'məndinqo',
    mas: 'masay',
    mdf: 'mokşa',
    mdr: 'mandar',
    men: 'mende',
    mer: 'meru',
    mfe: 'morisien',
    mg: 'malaqas',
    mga: 'orta irland',
    mgh: 'maxuva-meetto',
    mgo: 'meta’',
    mh: 'marşal',
    mi: 'māori',
    mic: 'mikmak',
    min: 'minanqkaban',
    mk: 'makedon',
    ml: 'malayalam',
    mn: 'monqol',
    mnc: 'mançu',
    mni: 'manipüri',
    moe: 'İnnu-aimun',
    moh: 'mohavk',
    mos: 'mosi',
    mr: 'marathi',
    ms: 'malay',
    mt: 'malta',
    mua: 'mundanq',
    mul: 'çoxsaylı dillər',
    mus: 'krik',
    mwl: 'mirand',
    mwr: 'maruari',
    my: 'birman',
    myv: 'erzya',
    mzn: 'mazandaran',
    na: 'nauru',
    nan: 'Min Nan',
    nap: 'neapolitan',
    naq: 'nama',
    nb: 'bokmal norveç',
    nd: 'şimali ndebele',
    nds: 'aşağı alman',
    'nds-NL': 'aşağı alman (Niderland)',
    ne: 'nepal',
    new: 'nevari',
    ng: 'ndonqa',
    nia: 'nias',
    niu: 'niyuan',
    nl: 'holland',
    'nl-BE': 'holland (Belçika)',
    nmg: 'kvasio',
    nn: 'nünorsk norveç',
    nnh: 'ngiemboon',
    no: 'norveç',
    nog: 'noqay',
    non: 'qədim nors',
    nqo: 'nko',
    nr: 'cənubi ndebele',
    nso: 'şimal soto',
    nus: 'nuer',
    nv: 'navayo',
    ny: 'nyanca',
    nym: 'nyamvezi',
    nyn: 'nyankol',
    nyo: 'nyoro',
    nzi: 'nzima',
    oc: 'oksitan',
    oj: 'ocibva',
    ojb: 'şimal-qərbi ocibva',
    ojc: 'Mərkəzi ocibva',
    ojs: 'ocikri',
    ojw: 'qərbi ocibva',
    oka: 'okanaqan',
    om: 'oromo',
    or: 'odiya',
    os: 'osetin',
    osa: 'osage',
    ota: 'osman',
    pa: 'pəncab',
    pag: 'panqasinan',
    pal: 'pəhləvi',
    pam: 'pampanqa',
    pap: 'papyamento',
    pau: 'palayan',
    pcm: 'niger kreol',
    peo: 'qədim fars',
    phn: 'foyenik',
    pi: 'pali',
    pis: 'picin',
    pl: 'polyak',
    pon: 'ponpey',
    pqm: 'malesit-passamakvodi',
    prg: 'pruss',
    pro: 'qədim provansal',
    ps: 'puştu',
    pt: 'portuqal',
    'pt-BR': 'portuqal (Braziliya)',
    'pt-PT': 'portuqal (Portuqaliya)',
    qu: 'keçua',
    quc: 'kiçe',
    raj: 'racastani',
    rap: 'rapanui',
    rar: 'rarotonqan',
    rhg: 'Rohinca',
    rm: 'romanş',
    rn: 'rundi',
    ro: 'rumın',
    'ro-MD': 'rumın (Moldova)',
    rof: 'rombo',
    rom: 'roman',
    ru: 'rus',
    rup: 'aroman',
    rw: 'kinyarvanda',
    rwk: 'rua',
    sa: 'sanskrit',
    sad: 'sandave',
    sah: 'saxa',
    sam: 'samaritan',
    saq: 'samburu',
    sas: 'sasak',
    sat: 'santal',
    sba: 'nqambay',
    sbp: 'sanqu',
    sc: 'sardin',
    scn: 'siciliya',
    sco: 'skots',
    sd: 'sindhi',
    sdh: 'cənubi kürd',
    se: 'şimali sami',
    seh: 'sena',
    sel: 'selkup',
    ses: 'koyraboro senni',
    sg: 'sanqo',
    sga: 'qədim irland',
    sh: 'serb-xorvat',
    shi: 'taçelit',
    shn: 'şan',
    si: 'sinhala',
    sid: 'sidamo',
    sk: 'slovak',
    sl: 'sloven',
    slh: 'cənubi luşusid',
    sm: 'samoa',
    sma: 'cənubi sami',
    smj: 'lule sami',
    smn: 'inari sami',
    sms: 'skolt sami',
    sn: 'şona',
    snk: 'soninke',
    so: 'somali',
    sog: 'soqdiyen',
    sq: 'alban',
    sr: 'serb',
    srn: 'sranan tonqo',
    srr: 'serer',
    ss: 'svati',
    ssy: 'saho',
    st: 'sesoto',
    str: 'streyts saliş',
    su: 'sundan',
    suk: 'sukuma',
    sus: 'susu',
    sux: 'sumeryan',
    sv: 'isveç',
    sw: 'suahili',
    'sw-CD': 'suahili (Konqo - Kinşasa)',
    swb: 'komor',
    syr: 'suriya',
    szl: 'silez dili',
    ta: 'tamil',
    tce: 'cənubi tuçon',
    te: 'teluqu',
    tem: 'timne',
    teo: 'teso',
    ter: 'tereno',
    tet: 'tetum',
    tg: 'tacik',
    tgx: 'taq',
    th: 'tay',
    tht: 'taltan',
    ti: 'tiqrin',
    tig: 'tiqre',
    tiv: 'tiv',
    tk: 'türkmən',
    tkl: 'tokelay',
    tl: 'taqaloq',
    tlh: 'klinqon',
    tli: 'tlinqit',
    tmh: 'tamaşek',
    tn: 'svana',
    to: 'tonqa',
    tog: 'nyasa tonqa',
    tok: 'tokipona',
    tpi: 'tok pisin',
    tr: 'türk',
    trv: 'taroko',
    ts: 'sonqa',
    tsi: 'simşyan',
    tt: 'tatar',
    ttm: 'şimali tuçon',
    tum: 'tumbuka',
    tvl: 'tuvalu',
    tw: 'tvi',
    twq: 'tasavaq',
    ty: 'taxiti',
    tyv: 'tuvinyan',
    tzm: 'Mərkəzi Atlas tamazicəsi',
    udm: 'udmurt',
    ug: 'uyğur',
    uga: 'uqarit',
    uk: 'ukrayna',
    umb: 'umbundu',
    und: 'naməlum dil',
    ur: 'urdu',
    uz: 'özbək',
    ve: 'venda',
    vec: 'venet dili',
    vi: 'vyetnam',
    vmw: 'makua dili',
    vo: 'volapük',
    vot: 'votik',
    vun: 'vunyo',
    wa: 'valun',
    wae: 'valles',
    wal: 'valamo',
    war: 'varay',
    was: 'vaşo',
    wbp: 'valpiri',
    wo: 'volof',
    wuu: 'vu',
    xal: 'kalmık',
    xh: 'xosa',
    xnr: 'kanqri',
    xog: 'soqa',
    yao: 'yao',
    yap: 'yapiz',
    yav: 'yanqben',
    ybb: 'yemba',
    yi: 'idiş',
    yo: 'yoruba',
    yrl: 'nyenqatu',
    yue: 'kanton',
    za: 'çjuan',
    zap: 'zapotek',
    zbl: 'blisimbols',
    zen: 'zenaqa',
    zgh: 'tamazi',
    zh: 'çin',
    'zh-Hans': 'sadələşmiş çin',
    'zh-Hant': 'ənənəvi çin',
    zu: 'zulu',
    zun: 'zuni',
    zxx: 'dil məzmunu yoxdur',
    zza: 'zaza',
  },
  hy: {
    aa: 'աֆարերեն',
    ab: 'աբխազերեն',
    ace: 'աչեհերեն',
    ach: 'աչոլի',
    ada: 'ադանգմերեն',
    ady: 'ադիղերեն',
    aeb: 'թունիսական արաբերեն',
    af: 'աֆրիկաանս',
    agq: 'աղեմ',
    ain: 'այներեն',
    ak: 'աքան',
    akk: 'աքքադերեն',
    ale: 'ալեութերեն',
    alt: 'հարավային ալթայերեն',
    am: 'ամհարերեն',
    an: 'արագոներեն',
    ang: 'հին անգլերեն',
    ann: 'օբոլո',
    anp: 'անգիկա',
    ar: 'արաբերեն',
    'ar-001': 'արաբերեն (Աշխարհ)',
    arc: 'արամեերեն',
    arn: 'մապուչի',
    arp: 'արապահո',
    arq: 'ալժիրական արաբերեն',
    ars: 'նեջդի արաբերեն',
    arz: 'եգիպտական արաբերեն',
    as: 'ասամերեն',
    asa: 'ասու',
    ase: 'ամերիկյան ժեստերի լեզու',
    ast: 'աստուրերեն',
    atj: 'աթիկամեկ',
    av: 'ավարերեն',
    awa: 'ավադհի',
    ay: 'այմարա',
    az: 'ադրբեջաներեն',
    ba: 'բաշկիրերեն',
    ban: 'բալիերեն',
    bas: 'բասաա',
    be: 'բելառուսերեն',
    bem: 'բեմբա',
    bez: 'բենա',
    bg: 'բուլղարերեն',
    bgc: 'հարյանվի',
    bgn: 'արևմտաբելուջիերեն',
    bho: 'բհոպուրի',
    bi: 'բիսլամա',
    bin: 'բինի',
    bla: 'սիկսիկա',
    blo: 'անիի',
    bm: 'բամբարա',
    bn: 'բենգալերեն',
    bo: 'տիբեթերեն',
    br: 'բրետոներեն',
    brx: 'բոդո',
    bs: 'բոսնիերեն',
    bss: 'աքուզ',
    bug: 'բուգիերեն',
    byn: 'բիլին',
    ca: 'կատալաներեն',
    cay: 'կայուգա',
    ccp: 'չակմա',
    ce: 'չեչեներեն',
    ceb: 'սեբուերեն',
    cgg: 'չիգա',
    ch: 'չամոռո',
    chk: 'տրուկերեն',
    chm: 'մարի',
    cho: 'չոկտո',
    chp: 'չիպևայան',
    chr: 'չերոկի',
    chy: 'շայեն',
    ckb: 'սորանի քրդերեն',
    clc: 'չիլկոտիներեն',
    co: 'կորսիկերեն',
    cop: 'ղպտերեն',
    crg: 'միչիֆ',
    crh: 'ղրիմյան թուրքերեն',
    crj: 'հարավ-արևելյան քրի',
    crk: 'հարթավայրերի քրի',
    crl: 'հյուսիս-արևելյան քրի',
    crm: 'մուզ քրի',
    crr: 'կարոլինական ալգոնկիներեն',
    crs: 'սեյշելյան խառնակերտ ֆրանսերեն',
    cs: 'չեխերեն',
    csw: 'ճահճի քրի',
    cu: 'սլավոներեն, եկեղեցական',
    cv: 'չուվաշերեն',
    cy: 'ուելսերեն',
    da: 'դանիերեն',
    dak: 'դակոտա',
    dar: 'դարգիներեն',
    dav: 'թաիթա',
    de: 'գերմաներեն',
    'de-AT': 'գերմաներեն (Ավստրիա)',
    'de-CH': 'գերմաներեն (Շվեյցարիա)',
    dgr: 'դոգրիբ',
    dje: 'զարմա',
    doi: 'դոգրի',
    dsb: 'ստորին սորբերեն',
    dua: 'դուալա',
    dv: 'դիվեհի',
    dyo: 'ջոլա-ֆոնյի',
    dz: 'ջոնգքհա',
    dzg: 'դազագա',
    ebu: 'էմբու',
    ee: 'էվե',
    efi: 'էֆիկ',
    egy: 'հին եգիպտերեն',
    eka: 'էկաջուկ',
    el: 'հունարեն',
    en: 'անգլերեն',
    'en-AU': 'անգլերեն (Ավստրալիա)',
    'en-CA': 'անգլերեն (Կանադա)',
    'en-GB': 'անգլերեն (Միացյալ Թագավորություն)',
    'en-US': 'անգլերեն (Միացյալ Նահանգներ)',
    eo: 'էսպերանտո',
    es: 'իսպաներեն',
    'es-419': 'իսպաներեն (Լատինական Ամերիկա)',
    'es-ES': 'իսպաներեն (Իսպանիա)',
    'es-MX': 'իսպաներեն (Մեքսիկա)',
    et: 'էստոներեն',
    eu: 'բասկերեն',
    ewo: 'էվոնդո',
    fa: 'պարսկերեն',
    'fa-AF': 'պարսկերեն (Աֆղանստան)',
    ff: 'ֆուլահ',
    fi: 'ֆիններեն',
    fil: 'ֆիլիպիներեն',
    fit: 'տորնադելեն ֆիններեն',
    fj: 'ֆիջիերեն',
    fo: 'ֆարյորերեն',
    fon: 'ֆոն',
    fr: 'ֆրանսերեն',
    'fr-CA': 'ֆրանսերեն (Կանադա)',
    'fr-CH': 'ֆրանսերեն (Շվեյցարիա)',
    frc: 'քաջունական ֆրանսերեն',
    fro: 'հին ֆրանսերեն',
    frr: 'հյուսիսային ֆրիզերեն',
    frs: 'արևելաֆրիզերեն',
    fur: 'ֆրիուլիերեն',
    fy: 'արևմտաֆրիզերեն',
    ga: 'իռլանդերեն',
    gaa: 'գայերեն',
    gag: 'գագաուզերեն',
    gbz: 'զրադաշտական դարի',
    gd: 'շոտլանդական գաելերեն',
    gez: 'գեեզ',
    gil: 'կիրիբատի',
    gl: 'գալիսերեն',
    gn: 'գուարանի',
    goh: 'հին վերին գերմաներեն',
    gor: 'գորոնտալո',
    got: 'գոթերեն',
    grc: 'հին հունարեն',
    gsw: 'շվեյցարական գերմաներեն',
    gu: 'գուջարաթի',
    guc: 'վայուու',
    guz: 'գուսի',
    gv: 'մեներեն',
    gwi: 'գվիչին',
    ha: 'հաուսա',
    hai: 'հայդա',
    haw: 'հավայիերեն',
    hax: 'հարավային հայդա',
    he: 'եբրայերեն',
    hi: 'հինդի',
    'hi-Latn': 'հինդի (լատինատառ)',
    hil: 'հիլիգայնոն',
    hmn: 'հմոնգ',
    hr: 'խորվաթերեն',
    hsb: 'վերին սորբերեն',
    hsn: 'սյան չինարեն',
    ht: 'խառնակերտ հայիթերեն',
    hu: 'հունգարերեն',
    hup: 'հուպա',
    hur: 'հալքոմելեմ',
    hy: 'հայերեն',
    hz: 'հերերո',
    ia: 'ինտերլինգուա',
    iba: 'իբաներեն',
    ibb: 'իբիբիո',
    id: 'ինդոնեզերեն',
    ie: 'ինտերլինգուե',
    ig: 'իգբո',
    ii: 'սիչուան',
    ikt: 'արևմտականադական ինուկտիտուտ',
    ilo: 'իլոկերեն',
    inh: 'ինգուշերեն',
    io: 'իդո',
    is: 'իսլանդերեն',
    it: 'իտալերեն',
    iu: 'ինուկտիտուտ',
    ja: 'ճապոներեն',
    jbo: 'լոժբան',
    jgo: 'նգոմբա',
    jmc: 'մաշամե',
    jv: 'ճավայերեն',
    ka: 'վրացերեն',
    kab: 'կաբիլերեն',
    kac: 'կաչիներեն',
    kaj: 'ջյու',
    kam: 'կամբա',
    kbd: 'կաբարդերեն',
    kcg: 'տիապ',
    kde: 'մակոնդե',
    kea: 'կաբուվերդերեն',
    kfo: 'կորո',
    kgp: 'կաինգան',
    kha: 'քասիերեն',
    khq: 'կոյրա չինի',
    ki: 'կիկույու',
    kj: 'կուանյամա',
    kk: 'ղազախերեն',
    kkj: 'կակո',
    kl: 'կալաալիսուտ',
    kln: 'կալենջին',
    km: 'քմերերեն',
    kmb: 'կիմբունդու',
    kn: 'կաննադա',
    ko: 'կորեերեն',
    koi: 'պերմյակ կոմիերեն',
    kok: 'կոնկանի',
    kpe: 'կպելլեերեն',
    kr: 'կանուրի',
    krc: 'կարաչայ-բալկարերեն',
    krl: 'կարելերեն',
    kru: 'կուրուխ',
    ks: 'քաշմիրերեն',
    ksb: 'շամբալա',
    ksf: 'բաֆիա',
    ksh: 'քյոլներեն',
    ku: 'քրդերեն',
    kum: 'կումիկերեն',
    kv: 'կոմիերեն',
    kw: 'կոռներեն',
    kwk: 'կվակվալա',
    kxv: 'կուվի',
    ky: 'ղրղզերեն',
    la: 'լատիներեն',
    lad: 'լադինո',
    lag: 'լանգի',
    lb: 'լյուքսեմբուրգերեն',
    lez: 'լեզգիերեն',
    lg: 'գանդա',
    li: 'լիմբուրգերեն',
    lij: 'լիգուրերեն',
    lil: 'լիլուետ',
    lkt: 'լակոտա',
    lmo: 'լոմբարդերեն',
    ln: 'լինգալա',
    lo: 'լաոսերեն',
    lou: 'լուիզիանական կրեոլերեն',
    loz: 'լոզի',
    lrc: 'հյուսիսային լուրիերեն',
    lsm: 'սաամերեն',
    lt: 'լիտվերեն',
    lu: 'լուբա-կատանգա',
    lua: 'լուբա-լուլուա',
    lun: 'լունդա',
    luo: 'լուո',
    lus: 'միզո',
    luy: 'լույա',
    lv: 'լատվիերեն',
    mad: 'մադուրերեն',
    mag: 'մագահի',
    mai: 'մայթիլի',
    mak: 'մակասարերեն',
    mas: 'մասաի',
    mdf: 'մոկշայերեն',
    men: 'մենդե',
    mer: 'մերու',
    mfe: 'մորիսյեն',
    mg: 'մալգաշերեն',
    mgh: 'մաքուա-մետտո',
    mgo: 'մետա',
    mh: 'մարշալերեն',
    mi: 'մաորի',
    mic: 'միկմակ',
    min: 'մինանգկաբաու',
    mk: 'մակեդոներեն',
    ml: 'մալայալամ',
    mn: 'մոնղոլերեն',
    mni: 'մանիպուրի',
    moe: 'իննու-այմուն',
    moh: 'մոհավք',
    mos: 'մոսսի',
    mr: 'մարաթի',
    mrj: 'արևմտամարիերեն',
    ms: 'մալայերեն',
    mt: 'մալթայերեն',
    mua: 'մունդանգ',
    mul: 'բազմալեզու',
    mus: 'մասքոջի',
    mwl: 'միրանդերեն',
    my: 'բիրմայերեն',
    myv: 'էրզյա',
    mzn: 'մազանդարաներեն',
    na: 'նաուրու',
    nap: 'նեապոլերեն',
    naq: 'նամա',
    nb: 'գրքային նորվեգերեն',
    nd: 'հյուսիսային նդեբելե',
    nds: 'ստորին գերմաներեն',
    'nds-NL': 'ստորին գերմաներեն (Նիդեռլանդներ)',
    ne: 'նեպալերեն',
    new: 'նեվարերեն',
    ng: 'նդոնգա',
    nia: 'նիասերեն',
    niu: 'նիուերեն',
    nl: 'հոլանդերեն',
    'nl-BE': 'հոլանդերեն (Բելգիա)',
    nmg: 'կվասիո',
    nn: 'նոր նորվեգերեն',
    nnh: 'նգիեմբուն',
    no: 'նորվեգերեն',
    nog: 'նոգայերեն',
    non: 'հին նորվեգերեն',
    nqo: 'նկո',
    nr: 'հարավային նդեբելե',
    nso: 'հյուսիսային սոթո',
    nus: 'նուեր',
    nv: 'նավախո',
    ny: 'նյանջա',
    nyn: 'նյանկոլե',
    oc: 'օքսիտաներեն',
    oj: 'օջիբվա',
    ojb: 'հյուսիս-արևմտյան օջիբվե',
    ojc: 'կենտրոնական օջիբվե',
    ojs: 'օջի քրի',
    ojw: 'արևմտյան օջիբվե',
    oka: 'օկանագան',
    om: 'օրոմո',
    or: 'օրիյա',
    os: 'օսերեն',
    osa: 'օսեյջ',
    ota: 'օսմաներեն',
    pa: 'փենջաբերեն',
    pag: 'պանգասինաներեն',
    pal: 'պահլավերեն',
    pam: 'պամպանգաերեն',
    pap: 'պապյամենտո',
    pau: 'պալաուերեն',
    pcd: 'պիկարդերեն',
    pcm: 'նիգերիական փիջին',
    pdc: 'փենսիլվանական գերմաներեն',
    pdt: 'պլատագերմաներեն',
    peo: 'հին պարսկերեն',
    pfl: 'պալատինյան գերմաներեն',
    phn: 'փյունիկերեն',
    pi: 'պալի',
    pis: 'փիջին',
    pl: 'լեհերեն',
    pms: 'պիեմոնտերեն',
    pnt: 'պոնտերեն',
    pon: 'պոնպեերեն',
    pqm: 'մալեսիտ-պասամակվոդի',
    prg: 'պրուսերեն',
    pro: 'հին պրովանսերեն',
    ps: 'փուշթու',
    pt: 'պորտուգալերեն',
    'pt-BR': 'պորտուգալերեն (Բրազիլիա)',
    'pt-PT': 'պորտուգալերեն (Պորտուգալիա)',
    qu: 'կեչուա',
    quc: 'քիչե',
    raj: 'ռաջաստաներեն',
    rap: 'ռապանուի',
    rar: 'ռարոտոնգաներեն',
    rgn: 'ռոմանիոլերեն',
    rhg: 'ռոհինջա',
    rif: 'ռիֆերեն',
    rm: 'ռոմանշերեն',
    rn: 'ռունդի',
    ro: 'ռումիներեն',
    'ro-MD': 'ռումիներեն (Մոլդովա)',
    rof: 'ռոմբո',
    rom: 'ռոմաներեն',
    rtm: 'ռոտուման',
    ru: 'ռուսերեն',
    rue: 'ռուսիներեն',
    rug: 'ռովիանա',
    rup: 'արոմաներեն',
    rw: 'կինյառուանդա',
    rwk: 'ռվա',
    sa: 'սանսկրիտ',
    sad: 'սանդավե',
    sah: 'յակուտերեն',
    saq: 'սամբուրու',
    sat: 'սանտալի',
    sba: 'նգամբայ',
    sbp: 'սանգու',
    sc: 'սարդիներեն',
    scn: 'սիցիլիերեն',
    sco: 'շոտլանդերեն',
    sd: 'սինդհի',
    sdh: 'հարավային քրդերեն',
    se: 'հյուսիսային սաամի',
    seh: 'սենա',
    ses: 'կոյրաբորո սեննի',
    sg: 'սանգո',
    sga: 'հին իռլանդերեն',
    sh: 'սերբա-խորվաթերեն',
    shi: 'տաշելհիթ',
    shn: 'շաներեն',
    si: 'սինհալերեն',
    sk: 'սլովակերեն',
    sl: 'սլովեներեն',
    slh: 'հարավային լուշուցիդ',
    sm: 'սամոաերեն',
    sma: 'հարավային սաամի',
    smj: 'լուլե սաամի',
    smn: 'ինարի սաամի',
    sms: 'սկոլտ սաամի',
    sn: 'շոնա',
    snk: 'սոնինկե',
    so: 'սոմալիերեն',
    sq: 'ալբաներեն',
    sr: 'սերբերեն',
    srn: 'սրանան տոնգո',
    ss: 'սվազերեն',
    ssy: 'սահոերեն',
    st: 'հարավային սոթո',
    str: 'սթրեյթս սալիշերեն',
    su: 'սունդաներեն',
    suk: 'սուկումա',
    sv: 'շվեդերեն',
    sw: 'սուահիլի',
    'sw-CD': 'սուահիլի (Կոնգո - Կինշասա)',
    swb: 'կոմորերեն',
    syr: 'ասորերեն',
    szl: 'սիլեզերեն',
    ta: 'թամիլերեն',
    tce: 'հարավային թուտչոնե',
    tcy: 'տուլու',
    te: 'թելուգու',
    tem: 'տեմնե',
    teo: 'տեսո',
    ter: 'տերենո',
    tet: 'տետում',
    tg: 'տաջիկերեն',
    tgx: 'թագիշ',
    th: 'թայերեն',
    tht: 'թալթան',
    ti: 'տիգրինյա',
    tig: 'տիգրե',
    tiv: 'տիվերեն',
    tk: 'թուրքմեներեն',
    tkl: 'տոկելաու',
    tkr: 'ցախուր',
    tl: 'տագալերեն',
    tlh: 'կլինգոն',
    tli: 'տլինգիտ',
    tly: 'թալիշերեն',
    tmh: 'տամաշեկ',
    tn: 'ցվանա',
    to: 'տոնգերեն',
    tok: 'տոկիպոնա',
    tpi: 'տոկ փիսին',
    tr: 'թուրքերեն',
    tru: 'տուրոյո',
    trv: 'տարոկո',
    ts: 'ցոնգա',
    tsd: 'ցակոներեն',
    tsi: 'ցիմշյան',
    tt: 'թաթարերեն',
    ttm: 'հյուսիսային թուտչոնե',
    tum: 'տումբուկա',
    tvl: 'թուվալուերեն',
    tw: 'տուի',
    twq: 'տասավաք',
    ty: 'թաիտերեն',
    tyv: 'տուվերեն',
    tzm: 'կենտրոնատլասյան թամազիղտ',
    udm: 'ուդմուրտերեն',
    ug: 'ույղուրերեն',
    uga: 'ուգարիտերեն',
    uk: 'ուկրաիներեն',
    umb: 'ումբունդու',
    und: 'անհայտ լեզու',
    ur: 'ուրդու',
    uz: 'ուզբեկերեն',
    vai: 'վաի',
    ve: 'վենդա',
    vec: 'վենետերեն',
    vep: 'վեպսերեն',
    vi: 'վիետնամերեն',
    vls: 'արևմտաֆլամանդերեն',
    vmw: 'մաքուա',
    vo: 'վոլապյուկ',
    vot: 'վոդերեն',
    vro: 'վորո',
    vun: 'վունջո',
    wa: 'վալոներեն',
    wae: 'վալսերեն',
    wal: 'վոլայտա',
    war: 'վարայերեն',
    was: 'վաշո',
    wbp: 'վարլպիրի',
    wo: 'վոլոֆ',
    wuu: 'վու չինարեն',
    xal: 'կալմիկերեն',
    xh: 'քոսա',
    xnr: 'կանգրի',
    xog: 'սոգա',
    yao: 'յաո',
    yap: 'յափերեն',
    yav: 'յանգբեն',
    ybb: 'եմբա',
    yi: 'իդիշ',
    yo: 'յորուբա',
    yrl: 'նինգաթու',
    yue: 'կանտոներեն',
    za: 'ժուանգ',
    zap: 'սապոտեկերեն',
    zea: 'զեյլանդերեն',
    zen: 'զենագա',
    zgh: 'ընդհանուր մարոկյան թամազիղտ',
    zh: 'չինարեն',
    'zh-Hans': 'պարզեցված չինարեն',
    'zh-Hant': 'ավանդական չինարեն',
    zu: 'զուլուերեն',
    zun: 'զունիերեն',
    zxx: 'առանց լեզվային բովանդակության',
    zza: 'զազաերեն',
  },
  ka: {
    aa: 'აფარი',
    ab: 'აფხაზური',
    ace: 'აჩეხური',
    ach: 'აჩოლი',
    ada: 'ადანგმე',
    ady: 'ადიღეური',
    ae: 'ავესტური',
    af: 'აფრიკაანსი',
    agq: 'აღემი',
    ain: 'აინუური',
    ak: 'აკანი',
    akk: 'აქადური',
    ale: 'ალეუტური',
    alt: 'სამხრეთ ალთაური',
    am: 'ამჰარული',
    an: 'არაგონული',
    ang: 'ძველი ინგლისური',
    ann: 'ობოლო',
    anp: 'ანგიკა',
    ar: 'არაბული',
    'ar-001': 'არაბული (მსოფლიო)',
    arc: 'არამეული',
    arn: 'მაპუდუნგუნი',
    arp: 'არაპაჰო',
    ars: 'ნაჯის არაბული',
    arw: 'არავაკი',
    as: 'ასამური',
    asa: 'ასუ',
    ast: 'ასტურიული',
    atj: 'ატიკამეკი',
    av: 'ხუნძური',
    awa: 'ავადი',
    ay: 'აიმარა',
    az: 'აზერბაიჯანული',
    ba: 'ბაშკირული',
    bal: 'ბელუჯი',
    ban: 'ბალინური',
    bas: 'ბასა',
    bax: 'ბამუნი',
    be: 'ბელარუსული',
    bej: 'ბეჯა',
    bem: 'ბემბა',
    bez: 'ბენა',
    bg: 'ბულგარული',
    bgc: 'ჰარიანვი',
    bgn: 'დასავლეთ ბელუჯი',
    bho: 'ბოჯპური',
    bi: 'ბისლამა',
    bin: 'ბინი',
    bla: 'სიკსიკა',
    blo: 'ანიი',
    bm: 'ბამბარა',
    bn: 'ბენგალური',
    bo: 'ტიბეტური',
    br: 'ბრეტონული',
    bra: 'ბრაჯი',
    brx: 'ბოდო',
    bs: 'ბოსნიური',
    bua: 'ბურიატული',
    bug: 'ბუგინური',
    byn: 'ბილინი',
    ca: 'კატალანური',
    cay: 'კაიუგა',
    ccp: 'ჩაკმა',
    ce: 'ჩეჩნური',
    ceb: 'სებუანო',
    cgg: 'ჩიგა',
    ch: 'ჩამორო',
    chb: 'ჩიბჩა',
    chk: 'ჩუკოტკური',
    chm: 'მარიული',
    chn: 'ჩინუკის ჟარგონი',
    cho: 'ჩოკტო',
    chp: 'ჩიპევიანი',
    chr: 'ჩეროკი',
    chy: 'ჩეიენი',
    ckb: 'ცენტრალური ქურთული',
    clc: 'ჩილკოტინი',
    co: 'კორსიკული',
    cop: 'კოპტური',
    cr: 'კრი',
    crg: 'მიჩიფი',
    crh: 'ყირიმულ-თურქული',
    crj: 'სამხრეთ-აღმოსავლეთის კრი',
    crk: 'დაბლობის კრი',
    crl: 'ჩრდილო-აღმოსავლეთის კრი',
    crm: 'მუსური კრი',
    crr: 'კაროლინური ალგონკინი',
    crs: 'სესელვა-კრეოლური ფრანგული',
    cs: 'ჩეხური',
    csb: 'კაშუბური',
    csw: 'ჭაობის კრი',
    cu: 'საეკლესიო სლავური',
    cv: 'ჩუვაშური',
    cy: 'უელსური',
    da: 'დანიური',
    dak: 'დაკოტური',
    dar: 'დარგუული',
    dav: 'ტაიტა',
    de: 'გერმანული',
    'de-AT': 'გერმანული (ავსტრია)',
    'de-CH': 'გერმანული (შვეიცარია)',
    del: 'დელავერული',
    den: 'სლეივი',
    dgr: 'დოგრიბი',
    din: 'დინკა',
    dje: 'ზარმა',
    doi: 'დოგრი',
    dsb: 'ქვემოსორბული',
    dua: 'დუალა',
    dum: 'საშუალო ჰოლანდიური',
    dv: 'დივეჰი',
    dyo: 'დიოლა',
    dyu: 'დიულა',
    dz: 'ძონგკხა',
    dzg: 'დაზაგა',
    ebu: 'ემბუ',
    ee: 'ევე',
    efi: 'ეფიკი',
    egy: 'ძველეგვიპტური',
    eka: 'ეკაჯუკი',
    el: 'ბერძნული',
    en: 'ინგლისური',
    'en-AU': 'ინგლისური (ავსტრალია)',
    'en-CA': 'ინგლისური (კანადა)',
    'en-GB': 'ინგლისური (გაერთიანებული სამეფო)',
    'en-US': 'ინგლისური (ამერიკის შეერთებული შტატები)',
    enm: 'საშუალო ინგლისური',
    eo: 'ესპერანტო',
    es: 'ესპანური',
    'es-419': 'ესპანური (ლათინური ამერიკა)',
    'es-ES': 'ესპანური (ესპანეთი)',
    'es-MX': 'ესპანური (მექსიკა)',
    et: 'ესტონური',
    eu: 'ბასკური',
    ewo: 'ევონდო',
    fa: 'სპარსული',
    'fa-AF': 'სპარსული (ავღანეთი)',
    ff: 'ფულა',
    fi: 'ფინური',
    fil: 'ფილიპინური',
    fj: 'ფიჯი',
    fo: 'ფარერული',
    fon: 'ფონი',
    fr: 'ფრანგული',
    'fr-CA': 'ფრანგული (კანადა)',
    'fr-CH': 'ფრანგული (შვეიცარია)',
    frc: 'კაჟუნური ფრანგული',
    frm: 'საშუალო ფრანგული',
    fro: 'ძველი ფრანგული',
    frr: 'ჩრდილოფრიზიული',
    frs: 'აღმოსავლეთფრიზიული',
    fur: 'ფრიულური',
    fy: 'დასავლეთფრიზიული',
    ga: 'ირლანდიური',
    gaa: 'გა',
    gag: 'გაგაუზური',
    gba: 'გბაია',
    gd: 'შოტლანდიური გელური',
    gez: 'გეეზი',
    gil: 'გილბერტული',
    gl: 'გალისიური',
    gmh: 'საშუალო ზემოგერმანული',
    gn: 'გუარანი',
    goh: 'ძველი ზემოგერმანული',
    gon: 'გონდი',
    gor: 'გორონტალო',
    got: 'გოთური',
    grc: 'ძველი ბერძნული',
    gsw: 'შვეიცარიული გერმანული',
    gu: 'გუჯარათი',
    guz: 'გუსიი',
    gv: 'მენური',
    gwi: 'გვიჩინი',
    ha: 'ჰაუსა',
    hai: 'ჰაიდა',
    haw: 'ჰავაიური',
    hax: 'სამხრეთული ჰაიდა',
    he: 'ებრაული',
    hi: 'ჰინდი',
    hil: 'ჰილიგაინონი',
    hit: 'ხეთური',
    hmn: 'ჰმონგი',
    hr: 'ხორვატული',
    hsb: 'ზემოსორბული',
    ht: 'ჰაიტიური კრეოლი',
    hu: 'უნგრული',
    hup: 'ჰუპა',
    hur: 'ჰალკომელემი',
    hy: 'სომხური',
    hz: 'ჰერერო',
    ia: 'ინტერლინგუალური',
    iba: 'იბანი',
    ibb: 'იბიბიო',
    id: 'ინდონეზიური',
    ie: 'ინტერლინგი',
    ig: 'იგბო',
    ii: 'სიჩუანის ი',
    ikt: 'დასავლეთ-კანადური ინუკტიტუტი',
    ilo: 'ილოკო',
    inh: 'ინგუშური',
    io: 'იდო',
    is: 'ისლანდიური',
    it: 'იტალიური',
    iu: 'ინუკტიტუტი',
    ja: 'იაპონური',
    jbo: 'ლოჟბანი',
    jgo: 'ნგომბა',
    jmc: 'კიმაშამი',
    jpr: 'იუდეო-სპარსული',
    jrb: 'იუდეო-არაბული',
    jv: 'იავური',
    ka: 'ქართული',
    kaa: 'ყარაყალფახური',
    kab: 'კაბილური',
    kac: 'კაჩინი',
    kaj: 'კაჯი',
    kam: 'კამბა',
    kbd: 'ყაბარდოული',
    kcg: 'ტიაპი',
    kde: 'მაკონდე',
    kea: 'კაბუვერდიანუ',
    kfo: 'კორო',
    kg: 'კონგო',
    kgp: 'კაინგანგი',
    kha: 'ხასი',
    khq: 'კოირა-ჩიინი',
    ki: 'კიკუიუ',
    kj: 'კუნამა',
    kk: 'ყაზახური',
    kkj: 'კაკო',
    kl: 'დასავლეთ გრენლანდიური',
    kln: 'კალენჯინი',
    km: 'ქმერული',
    kmb: 'კიმბუნდუ',
    kn: 'კანადა',
    ko: 'კორეული',
    koi: 'კომი-პერმიაკული',
    kok: 'კონკანი',
    kos: 'კუსაიე',
    kpe: 'კპელე',
    kr: 'კანური',
    krc: 'ყარაჩაულ-ბალყარული',
    krl: 'კარელიური',
    kru: 'კურუქი',
    ks: 'ქაშმირული',
    ksb: 'შამბალა',
    ksf: 'ბაფია',
    ksh: 'კიოლში',
    ku: 'ქურთული',
    kum: 'ყუმუხური',
    kut: 'კუტენაი',
    kv: 'კომი',
    kw: 'კორნული',
    kwk: 'კვაკვალა',
    kxv: 'კუვი',
    ky: 'ყირგიზული',
    la: 'ლათინური',
    lad: 'ლადინო',
    lag: 'ლანგი',
    lah: 'ლანდა',
    lam: 'ლამბა',
    lb: 'ლუქსემბურგული',
    lez: 'ლეზგიური',
    lg: 'განდა',
    li: 'ლიმბურგული',
    lij: 'ლიგურიული',
    lil: 'ლილიეტი',
    lkt: 'ლაკოტა',
    lmo: 'ლომბარდიული',
    ln: 'ლინგალა',
    lo: 'ლაოსური',
    lol: 'მონგო',
    lou: 'ლუიზიანას კრეოლური',
    loz: 'ლოზი',
    lrc: 'ჩრდილოეთ ლური',
    lsm: 'სამია',
    lt: 'ლიეტუვური',
    lu: 'ლუბა-კატანგა',
    lua: 'ლუბა-ლულუა',
    lui: 'ლუისენიო',
    lun: 'ლუნდა',
    luo: 'ლუო',
    lus: 'მიზო',
    luy: 'ლუჰია',
    lv: 'ლატვიური',
    mad: 'მადურული',
    maf: 'მაფა',
    mag: 'მაგაჰი',
    mai: 'მაითილი',
    mak: 'მაკასარი',
    mas: 'მასაი',
    mde: 'მაბა',
    mdf: 'მოქშა',
    men: 'მენდე',
    mer: 'მერუ',
    mfe: 'მორისიენი',
    mg: 'მალაგასიური',
    mga: 'საშუალო ირლანდიური',
    mgh: 'მაქუვა-მეეტო',
    mgo: 'მეტა-ენა',
    mh: 'მარშალური',
    mi: 'მაორი',
    mic: 'მიკმაკი',
    min: 'მინანგკაბაუ',
    mk: 'მაკედონური',
    ml: 'მალაიალამური',
    mn: 'მონღოლური',
    mnc: 'მანჯურიული',
    mni: 'მანიპური',
    moe: 'ინუ-აიმუნი',
    moh: 'მოჰაუკური',
    mos: 'მოსი',
    mr: 'მარათჰი',
    ms: 'მალაიური',
    mt: 'მალტური',
    mua: 'მუნდანგი',
    mul: 'სხვადასხვა ენა',
    mus: 'კრიკი',
    mwl: 'მირანდული',
    mwr: 'მარვარი',
    my: 'ბირმული',
    mye: 'მიენე',
    myv: 'ერზია',
    mzn: 'მაზანდერანული',
    na: 'ნაურუ',
    nap: 'ნეაპოლიტანური',
    naq: 'ნამა',
    nb: 'ნორვეგიული ბუკმოლი',
    nd: 'ჩრდილოეთ ნდებელე',
    nds: 'ქვემოგერმანული',
    'nds-NL': 'ქვემოგერმანული (ნიდერლანდები)',
    ne: 'ნეპალური',
    new: 'ნევარი',
    ng: 'ნდონგა',
    nia: 'ნიასი',
    niu: 'ნიუე',
    nl: 'ნიდერლანდური',
    'nl-BE': 'ნიდერლანდური (ბელგია)',
    nmg: 'კვასიო',
    nn: 'ნორვეგიული ნიუნორსკი',
    nnh: 'ნგიმბუნი',
    no: 'ნორვეგიული',
    nog: 'ნოღაური',
    non: 'ძველსკანდინავიური',
    nqo: 'ნკო',
    nr: 'სამხრეთ ნდებელური',
    nso: 'ჩრდილოეთ სოთო',
    nus: 'ნუერი',
    nv: 'ნავახო',
    nwc: 'კლასიკური ნევარული',
    ny: 'ნიანჯა',
    nym: 'ნიამვეზი',
    nyn: 'ნიანკოლე',
    nyo: 'ნიორო',
    nzi: 'ნზიმა',
    oc: 'ოქსიტანური',
    oj: 'ოჯიბვე',
    ojb: 'ჩრდილო-დასავლეთის ოჯიბვა',
    ojc: 'ცენტრალური ოჯიბვე',
    ojs: 'ოჯი-კრი',
    ojw: 'დასავლეთის ოჯიბვა',
    oka: 'ოკანაგანი',
    om: 'ორომო',
    or: 'ორია',
    os: 'ოსური',
    pa: 'პენჯაბური',
    pag: 'პანგასინანი',
    pal: 'ფალაური',
    pam: 'პამპანგა',
    pap: 'პაპიამენტო',
    pau: 'ფალაუანი',
    pcm: 'ნიგერიული კრეოლური',
    peo: 'ძველი სპარსული',
    phn: 'ფინიკიური',
    pi: 'პალი',
    pis: 'პიჯინი',
    pl: 'პოლონური',
    pqm: 'მალისეტ-პასამაკვოდი',
    prg: 'პრუსიული',
    pro: 'ძველი პროვანსული',
    ps: 'პუშტუ',
    pt: 'პორტუგალიური',
    'pt-BR': 'პორტუგალიური (ბრაზილია)',
    'pt-PT': 'პორტუგალიური (პორტუგალია)',
    qu: 'კეჩუა',
    quc: 'კიჩე',
    raj: 'რაჯასთანი',
    rap: 'რაპანუი',
    rar: 'რაროტონგული',
    rhg: 'როჰინგა',
    rm: 'რეტორომანული',
    rn: 'რუნდი',
    ro: 'რუმინული',
    'ro-MD': 'რუმინული (მოლდოვა)',
    rof: 'რომბო',
    rom: 'ბოშური',
    ru: 'რუსული',
    rup: 'არომანული',
    rw: 'კინიარუანდა',
    rwk: 'რუა',
    sa: 'სანსკრიტი',
    sad: 'სანდავე',
    sah: 'იაკუტური',
    sam: 'სამარიულ-არამეული',
    saq: 'სამბურუ',
    sat: 'სანტალი',
    sba: 'ნგამბაი',
    sbp: 'სანგუ',
    sc: 'სარდინიული',
    scn: 'სიცილიური',
    sco: 'შოტლანდიური',
    sd: 'სინდჰური',
    sdh: 'სამხრეთქურთული',
    se: 'ჩრდილოეთ საამური',
    see: 'სენეკა',
    seh: 'სენა',
    sel: 'სელკუპური',
    ses: 'კოირაბორო-სენი',
    sg: 'სანგო',
    sga: 'ძველი ირლანდიური',
    sh: 'სერბულ-ხორვატული',
    shi: 'შილჰა',
    shn: 'შანი',
    shu: 'ჩადური არაბული',
    si: 'სინჰალური',
    sk: 'სლოვაკური',
    sl: 'სლოვენური',
    slh: 'სამხრეთული ლუშუციდი',
    sm: 'სამოა',
    sma: 'სამხრეთსამური',
    smj: 'ლულე-საამური',
    smn: 'ინარი-საამური',
    sms: 'სკოლტ-საამური',
    sn: 'შონა',
    snk: 'სონინკე',
    so: 'სომალიური',
    sq: 'ალბანური',
    sr: 'სერბული',
    srn: 'სრანან ტონგო',
    ss: 'სუატი',
    ssy: 'საჰო',
    st: 'სამხრეთ სოთოს ენა',
    str: 'სტრეიტს სალიში',
    su: 'სუნდური',
    suk: 'სუკუმა',
    sux: 'შუმერული',
    sv: 'შვედური',
    sw: 'სუაჰილი',
    'sw-CD': 'სუაჰილი (კონგო - კინშასა)',
    swb: 'კომორული',
    syc: 'კლასიკური სირიული',
    syr: 'სირიული',
    szl: 'სილესიური',
    ta: 'ტამილური',
    tce: 'სამხრეთ ტუჩონი',
    te: 'ტელუგუ',
    tem: 'ტინმე',
    teo: 'ტესო',
    tet: 'ტეტუმი',
    tg: 'ტაჯიკური',
    tgx: 'ტაგიში',
    th: 'ტაილანდური',
    tht: 'ტალტანი',
    ti: 'ტიგრინია',
    tig: 'თიგრე',
    tk: 'თურქმენული',
    tlh: 'კლინგონი',
    tli: 'ტლინგიტი',
    tn: 'ტსვანა',
    to: 'ტონგანური',
    tok: 'ტოკი-პონა',
    tpi: 'ტოკ-პისინი',
    tr: 'თურქული',
    trv: 'ტაროკო',
    ts: 'ტსონგა',
    tt: 'თათრული',
    ttm: 'ჩრდილოეთ ტუჩონი',
    tum: 'ტუმბუკა',
    tvl: 'ტუვალუ',
    tw: 'თუი',
    twq: 'ტასავაქი',
    ty: 'ტაიტური',
    tyv: 'ტუვა',
    tzm: 'ცენტრალური მოროკოს ტამაზიგხტი',
    udm: 'უდმურტული',
    ug: 'უიღურული',
    uga: 'უგარითული',
    uk: 'უკრაინული',
    umb: 'უმბუნდუ',
    und: 'უცნობი ენა',
    ur: 'ურდუ',
    uz: 'უზბეკური',
    vai: 'ვაი',
    ve: 'ვენდა',
    vec: 'ვენეციური',
    vi: 'ვიეტნამური',
    vmw: 'მაკჰუვა',
    vo: 'ვოლაპუკი',
    vun: 'ვუნჯო',
    wa: 'ვალონური',
    wae: 'ვალსერი',
    wal: 'ველაითა',
    war: 'ვარაი',
    wbp: 'ვალპირი',
    wo: 'ვოლოფური',
    wuu: 'ვუ',
    xal: 'ყალმუხური',
    xh: 'ქჰოსა',
    xnr: 'კანგრი',
    xog: 'სოგა',
    yav: 'იანგბენი',
    ybb: 'იემბა',
    yi: 'იდიში',
    yo: 'იორუბა',
    yrl: 'ნენგატუ',
    yue: 'კანტონური',
    za: 'ზჰუანგი',
    zbl: 'ბლისსიმბოლოები',
    zen: 'ზენაგა',
    zgh: 'სტანდარტული მაროკოული ტამაზიგხტი',
    zh: 'ჩინური',
    'zh-Hans': 'გამარტივებული ჩინური',
    'zh-Hant': 'ტრადიციული ჩინური',
    zu: 'ზულუ',
    zun: 'ზუნი',
    zxx: 'ლინგვისტური შიგთავსი არ არის',
    zza: 'ზაზაკი',
  },
  sq: {
    aa: 'afarisht',
    ab: 'abkazisht',
    ace: 'akinezisht',
    ada: 'andangmeisht',
    ady: 'adigisht',
    af: 'afrikanisht',
    agq: 'agemisht',
    ain: 'ajnuisht',
    ak: 'akanisht',
    ale: 'aleutisht',
    alt: 'altaishte jugore',
    am: 'amarisht',
    an: 'aragonezisht',
    ann: 'oboloisht',
    anp: 'angikisht',
    ar: 'arabisht',
    'ar-001': 'arabisht (Bota)',
    arn: 'mapuçisht',
    arp: 'arapahoisht',
    ars: 'arabishte naxhdi',
    as: 'asamezisht',
    asa: 'asuisht',
    ast: 'asturisht',
    atj: 'atikamekisht',
    av: 'avarikisht',
    awa: 'auadhisht',
    ay: 'ajmarisht',
    az: 'azerbajxhanisht',
    ba: 'bashkirisht',
    ban: 'balinezisht',
    bas: 'basaisht',
    be: 'bjellorusisht',
    bem: 'bembaisht',
    bez: 'benaisht',
    bg: 'bullgarisht',
    bgc: 'harjanvisht',
    bgn: 'balokishte perëndimore',
    bho: 'boxhpurisht',
    bi: 'bislamisht',
    bin: 'binisht',
    bla: 'siksikaisht',
    blo: 'anisht',
    bm: 'bambarisht',
    bn: 'bengalisht',
    bo: 'tibetisht',
    br: 'bretonisht',
    brx: 'bodoisht',
    bs: 'boshnjakisht',
    bug: 'buginezisht',
    byn: 'blinisht',
    ca: 'katalonisht',
    cay: 'kajugaisht',
    ccp: 'çakmaisht',
    ce: 'çeçenisht',
    ceb: 'sebuanisht',
    cgg: 'çigisht',
    ch: 'kamoroisht',
    chk: 'çukezisht',
    chm: 'marisht',
    cho: 'çoktauisht',
    chp: 'çipeuajanisht',
    chr: 'çerokisht',
    chy: 'çejenisht',
    ckb: 'kurdishte qendrore',
    clc: 'çilkotinisht',
    co: 'korsikisht',
    crg: 'miçifisht',
    crj: 'krijishte juglindore',
    crk: 'krijishte fusharake',
    crl: 'krijishte verilindore',
    crm: 'krijishte e Muzit',
    crr: 'algonkuianishte e Karolinës',
    crs: 'frëngjishte kreole seselve',
    cs: 'çekisht',
    csw: 'krijishte e moçaleve (Ontario)',
    cu: 'sllavishte kishtare',
    cv: 'çuvashisht',
    cy: 'uellsisht',
    da: 'danisht',
    dak: 'dakotisht',
    dar: 'darguaisht',
    dav: 'tajtaisht',
    de: 'gjermanisht',
    'de-AT': 'gjermanisht (Austri)',
    'de-CH': 'gjermanisht (Zvicër)',
    dgr: 'dogribisht',
    dje: 'zarmaisht',
    doi: 'dogrisht',
    dsb: 'sorbishte e poshtme',
    dua: 'dualaisht',
    dv: 'divehisht',
    dyo: 'xhulafonjisht',
    dz: 'xhongaisht',
    dzg: 'dazagauisht',
    ebu: 'embuisht',
    ee: 'eveisht',
    efi: 'efikisht',
    eka: 'ekajukisht',
    el: 'greqisht',
    en: 'anglisht',
    'en-AU': 'anglisht (Australi)',
    'en-CA': 'anglisht (Kanada)',
    'en-GB': 'anglisht (Mbretëria e Bashkuar)',
    'en-US': 'anglisht (SHBA)',
    eo: 'esperanto',
    es: 'spanjisht',
    'es-419': 'spanjisht (Amerika Latine)',
    'es-ES': 'spanjisht (Spanjë)',
    'es-MX': 'spanjisht (Meksikë)',
    et: 'estonisht',
    eu: 'baskisht',
    ewo: 'euondoisht',
    fa: 'persisht',
    'fa-AF': 'persisht (Afganistan)',
    ff: 'fulaisht',
    fi: 'finlandisht',
    fil: 'filipinisht',
    fj: 'fixhianisht',
    fo: 'faroisht',
    fon: 'fonisht',
    fr: 'frëngjisht',
    'fr-CA': 'frëngjisht (Kanada)',
    'fr-CH': 'frëngjisht (Zvicër)',
    frc: 'frëngjishte kajune',
    frr: 'frisianishte veriore',
    fur: 'friulianisht',
    fy: 'frizianishte perëndimore',
    ga: 'irlandisht',
    gaa: 'gaisht',
    gag: 'gagauzisht',
    gd: 'galishte skoceze',
    gez: 'gizisht',
    gil: 'gilbertazisht',
    gl: 'galicisht',
    gn: 'guaranisht',
    gor: 'gorontaloisht',
    gsw: 'gjermanishte zvicerane',
    gu: 'guxharatisht',
    guz: 'gusisht',
    gv: 'manksisht',
    gwi: 'guiçinisht',
    ha: 'hausisht',
    hai: 'haidaisht',
    haw: 'havaisht',
    hax: 'haidaishte jugore',
    he: 'hebraisht',
    hi: 'indisht',
    'hi-Latn': 'hindisht (latine)',
    hil: 'hiligajnonisht',
    hmn: 'hmongisht',
    hr: 'kroatisht',
    hsb: 'sorbishte e sipërme',
    ht: 'kreolishte e Haitit',
    hu: 'hungarisht',
    hup: 'hupaisht',
    hur: 'halkemejlemisht',
    hy: 'armenisht',
    hz: 'hereroisht',
    ia: 'interlingua',
    iba: 'ibanisht',
    ibb: 'ibibioisht',
    id: 'indonezisht',
    ie: 'gjuha oksidentale',
    ig: 'igboisht',
    ii: 'sishuanisht',
    ikt: 'inuktitutishte kanadeze perëndimore',
    ilo: 'ilokoisht',
    inh: 'ingushisht',
    io: 'idoisht',
    is: 'islandisht',
    it: 'italisht',
    iu: 'inuktitutisht',
    ja: 'japonisht',
    jbo: 'lojbanisht',
    jgo: 'ngombisht',
    jmc: 'maçamisht',
    jv: 'javanisht',
    ka: 'gjeorgjisht',
    kab: 'kabilisht',
    kac: 'kaçinisht',
    kaj: 'kajeisht',
    kam: 'kambaisht',
    kbd: 'kabardianisht',
    kcg: 'tjapisht',
    kde: 'makondisht',
    kea: 'kreolishte e Kepit të Gjelbër',
    kfo: 'koroisht',
    kgp: 'kaingangisht',
    kha: 'kasisht',
    khq: 'kojraçinisht',
    ki: 'kikujuisht',
    kj: 'kuanjamaisht',
    kk: 'kazakisht',
    kkj: 'kakoisht',
    kl: 'kalalisutisht',
    kln: 'kalenxhinisht',
    km: 'kmerisht',
    kmb: 'kimbunduisht',
    kn: 'kanadisht',
    ko: 'koreanisht',
    koi: 'komi-parmjakisht',
    kok: 'konkanisht',
    kpe: 'kpeleisht',
    kr: 'kanurisht',
    krc: 'karaçaj-balkarisht',
    krl: 'karelianisht',
    kru: 'kurukisht',
    ks: 'kashmirisht',
    ksb: 'shambalisht',
    ksf: 'bafianisht',
    ksh: 'këlnisht',
    ku: 'kurdisht',
    kum: 'kumikisht',
    kv: 'komisht',
    kw: 'kornisht',
    kwk: 'kuakualaisht',
    kxv: 'kuvisht',
    ky: 'kirgizisht',
    la: 'latinisht',
    lad: 'ladinoisht',
    lag: 'langisht',
    lb: 'luksemburgisht',
    lez: 'lezgianisht',
    lg: 'gandaisht',
    li: 'limburgisht',
    lij: 'ligurianisht',
    lil: 'lilluetisht',
    lkt: 'lakotisht',
    lmo: 'lombardisht',
    ln: 'lingalisht',
    lo: 'laosisht',
    lou: 'kreolishte e Luizianës',
    loz: 'lozisht',
    lrc: 'lurishte veriore',
    lsm: 'samisht',
    lt: 'lituanisht',
    lu: 'luba-katangaisht',
    lua: 'luba-luluaisht',
    lun: 'lundaisht',
    luo: 'luoisht',
    lus: 'mizoisht',
    luy: 'lujaisht',
    lv: 'letonisht',
    mad: 'madurezisht',
    mag: 'magaisht',
    mai: 'maitilisht',
    mak: 'makasarisht',
    mas: 'masaisht',
    mdf: 'mokshaisht',
    men: 'mendisht',
    mer: 'meruisht',
    mfe: 'morisjenisht',
    mg: 'madagaskarisht',
    mgh: 'makua-mitoisht',
    mgo: 'metaisht',
    mh: 'marshallisht',
    mi: 'maorisht',
    mic: 'mikmakisht',
    min: 'minangkabauisht',
    mk: 'maqedonisht',
    ml: 'malajalamisht',
    mn: 'mongolisht',
    mni: 'manipurisht',
    moe: 'inuaimunisht',
    moh: 'mohokisht',
    mos: 'mosisht',
    mr: 'maratisht',
    ms: 'malajisht',
    mt: 'maltisht',
    mua: 'mundangisht',
    mul: 'gjuhë të shumëfishta',
    mus: 'krikisht',
    mwl: 'mirandisht',
    my: 'birmanisht',
    myv: 'erzjaisht',
    mzn: 'mazanderanisht',
    na: 'nauruisht',
    nap: 'napoletanisht',
    naq: 'namaisht',
    nb: 'norvegjishte letrare',
    nd: 'ndebelishte veriore',
    nds: 'gjermanishte e vendeve të ulëta',
    'nds-NL': 'gjermanishte e vendeve të ulëta (Holandë)',
    ne: 'nepalisht',
    new: 'neuarisht',
    ng: 'ndongaisht',
    nia: 'niasisht',
    niu: 'niueanisht',
    nl: 'holandisht',
    'nl-BE': 'holandisht (Belgjikë)',
    nmg: 'kuasisht',
    nn: 'norvegjishte nynorsk',
    nnh: 'ngiembunisht',
    no: 'norvegjisht',
    nog: 'nogajisht',
    nqo: 'nkoisht',
    nr: 'ndebelishte jugore',
    nso: 'sotoishte veriore',
    nus: 'nuerisht',
    nv: 'navahoisht',
    ny: 'nianjisht',
    nyn: 'niankolisht',
    oc: 'oksitanisht',
    ojb: 'oxhibuaishte verilindore',
    ojc: 'oxhibuaishte qendrore',
    ojs: 'oxhikrijisht',
    ojw: 'oxhibuaishte perëndimore',
    oka: 'okanaganisht',
    om: 'oromoisht',
    or: 'odisht',
    os: 'osetisht',
    pa: 'punxhabisht',
    pag: 'pangasinanisht',
    pam: 'pampangaisht',
    pap: 'papiamentisht',
    pau: 'paluanisht',
    pcm: 'pixhinishte nigeriane',
    pis: 'pixhinisht',
    pl: 'polonisht',
    pqm: 'malisit-pasamakuadisht',
    prg: 'prusisht',
    ps: 'pashtoisht',
    pt: 'portugalisht',
    'pt-BR': 'portugalisht (Brazil)',
    'pt-PT': 'portugalisht (Portugali)',
    qu: 'keçuaisht',
    quc: 'kiçeisht',
    raj: 'raxhastanisht',
    rap: 'rapanuisht',
    rar: 'rarontonganisht',
    rhg: 'rohingiaisht',
    rm: 'retoromanisht',
    rn: 'rundisht',
    ro: 'rumanisht',
    'ro-MD': 'rumanisht (Moldavi)',
    rof: 'romboisht',
    ru: 'rusisht',
    rup: 'vllahisht',
    rw: 'kiniaruandisht',
    rwk: 'ruaisht',
    sa: 'sanskritisht',
    sad: 'sandauisht',
    sah: 'sakaisht',
    saq: 'samburisht',
    sat: 'santalisht',
    sba: 'ngambajisht',
    sbp: 'sanguisht',
    sc: 'sardenjisht',
    scn: 'siçilianisht',
    sco: 'skotisht',
    sd: 'sindisht',
    sdh: 'kurdishte jugore',
    se: 'samishte veriore',
    seh: 'senaisht',
    ses: 'senishte kojrabore',
    sg: 'sangoisht',
    sh: 'serbo-kroatisht',
    shi: 'taçelitisht',
    shn: 'shanisht',
    si: 'sinhalisht',
    sk: 'sllovakisht',
    sl: 'sllovenisht',
    slh: 'lashutsidishte jugore',
    sm: 'samoanisht',
    sma: 'samishte jugore',
    smj: 'samishte lule',
    smn: 'samishte inari',
    sms: 'samishte skolti',
    sn: 'shonisht',
    snk: 'soninkisht',
    so: 'somalisht',
    sq: 'shqip',
    sr: 'serbisht',
    srn: 'srananisht (sranantongoisht)',
    ss: 'suatisht',
    ssy: 'sahoisht',
    st: 'sotoishte jugore',
    str: 'sejlishte e Ngushticave të Rozarios',
    su: 'sundanisht',
    suk: 'sukumaisht',
    sv: 'suedisht',
    sw: 'suahilisht',
    'sw-CD': 'suahilisht (Kongo-Kinshasa)',
    swb: 'kamorianisht',
    syr: 'siriakisht',
    szl: 'silesisht',
    ta: 'tamilisht',
    tce: 'tatshonishte jugore',
    te: 'teluguisht',
    tem: 'timneisht',
    teo: 'tesoisht',
    tet: 'tetumisht',
    tg: 'taxhikisht',
    tgx: 'tagishisht',
    th: 'tajlandisht',
    tht: 'taltanisht',
    ti: 'tigrinjaisht',
    tig: 'tigreisht',
    tk: 'turkmenisht',
    tlh: 'klingonisht',
    tli: 'tlingitisht',
    tn: 'cuanaisht',
    to: 'tonganisht',
    tok: 'tokiponaisht',
    tpi: 'pisinishte toku',
    tr: 'turqisht',
    trv: 'torokoisht',
    ts: 'congaisht',
    tt: 'tatarisht',
    ttm: 'taçoneishte veriore',
    tum: 'tumbukaisht',
    tvl: 'tuvaluisht',
    tw: 'tuisht',
    twq: 'tasavakisht',
    ty: 'tahitisht',
    tyv: 'tuvinianisht',
    tzm: 'tamazajtisht e Atlasit Qendror',
    udm: 'udmurtisht',
    ug: 'ujgurisht',
    uk: 'ukrainisht',
    umb: 'umbunduisht',
    und: 'E panjohur',
    ur: 'urduisht',
    uz: 'uzbekisht',
    vai: 'vaisht',
    ve: 'vendaisht',
    vec: 'venetisht',
    vi: 'vietnamisht',
    vmw: 'makuvaisht',
    vo: 'volapykisht',
    vun: 'vunxhoisht',
    wa: 'ualunisht',
    wae: 'ualserisht',
    wal: 'ulajtaisht',
    war: 'uarajisht',
    wbp: 'uarlpirisht',
    wo: 'uolofisht',
    wuu: 'kinezishte vu',
    xal: 'kalmikisht',
    xh: 'xhosaisht',
    xnr: 'kangrisht',
    xog: 'sogisht',
    yav: 'jangbenisht',
    ybb: 'jembaisht',
    yi: 'jidisht',
    yo: 'jorubaisht',
    yrl: 'nejengatuisht',
    yue: 'kantonezisht',
    za: 'zhuangisht',
    zgh: 'tamaziatishte standarde marokene',
    zh: 'kinezisht',
    'zh-Hans': 'kinezishte e thjeshtuar',
    'zh-Hant': 'kinezishte tradicionale',
    zu: 'zuluisht',
    zun: 'zunisht',
    zxx: 'nuk ka përmbajtje gjuhësore',
    zza: 'zazaisht',
  },
  mk: {
    aa: 'афарски',
    ab: 'апхаски',
    ace: 'ачешки',
    ach: 'аколи',
    ada: 'адангме',
    ady: 'адигејски',
    ae: 'авестански',
    aeb: 'туниски арапски',
    af: 'африканс',
    afh: 'африхили',
    agq: 'агемски',
    ain: 'ајну',
    ak: 'акански',
    akk: 'акадски',
    akz: 'алабамски',
    ale: 'алеутски',
    aln: 'гешки албански',
    alt: 'јужноалтајски',
    am: 'амхарски',
    an: 'арагонски',
    ang: 'староанглиски',
    ann: 'оболо',
    anp: 'ангика',
    ar: 'арапски',
    'ar-001': 'арапски (Свет)',
    arc: 'арамејски',
    arn: 'мапучки',
    aro: 'араона',
    arp: 'арапахо',
    arq: 'алжирски арапски',
    ars: 'неџдиски арапски',
    arw: 'аравачки',
    ary: 'марокански арапски',
    arz: 'египетски арапски',
    as: 'асамски',
    asa: 'асу',
    ase: 'американски знаковен јазик',
    ast: 'астурски',
    atj: 'атикамек',
    av: 'аварски',
    avk: 'котава',
    awa: 'авади',
    ay: 'ајмарски',
    az: 'азербејџански',
    ba: 'башкирски',
    bal: 'белуџиски',
    ban: 'балиски',
    bar: 'баварски',
    bas: 'баса',
    bax: 'бамунски',
    bbc: 'тоба',
    bbj: 'гомала',
    be: 'белоруски',
    bej: 'беџа',
    bem: 'бемба',
    bew: 'бетавски',
    bez: 'бена',
    bfd: 'бафут',
    bfq: 'бадага',
    bg: 'бугарски',
    bgc: 'харијанви',
    bgn: 'западен балочи',
    bho: 'боџпури',
    bi: 'бислама',
    bik: 'биколски',
    bin: 'бини',
    bjn: 'банџарски',
    bkm: 'ком',
    bla: 'сиксика',
    blo: 'ании',
    bm: 'бамбара',
    bn: 'бенгалски',
    bo: 'тибетски',
    bpy: 'бишнуприја',
    bqi: 'бахтијарски',
    br: 'бретонски',
    bra: 'брај',
    brh: 'брахујски',
    brx: 'бодо',
    bs: 'босански',
    bss: 'акосе',
    bua: 'бурјатски',
    bug: 'бугиски',
    bum: 'булу',
    byn: 'биленски',
    byv: 'медумба',
    ca: 'каталонски',
    cad: 'кадо',
    car: 'карипски',
    cay: 'кајуга',
    cch: 'ацам',
    ccp: 'чакмански',
    ce: 'чеченски',
    ceb: 'себуански',
    cgg: 'чига',
    ch: 'чаморски',
    chb: 'чибча',
    chg: 'чагатајски',
    chk: 'чучки',
    chm: 'мариски',
    chn: 'чинучки жаргон',
    cho: 'чоктавски',
    chp: 'чипевјански',
    chr: 'чероки',
    chy: 'чејенски',
    ckb: 'централнокурдски',
    clc: 'чилкотински',
    co: 'корзикански',
    cop: 'коптски',
    cps: 'капизнон',
    cr: 'кри',
    crg: 'мичиф',
    crh: 'кримскотурски',
    crj: 'југоисточен кријски',
    crk: 'прериски кријски',
    crl: 'североисточен кријски',
    crm: 'лосовски кријски',
    crr: 'каролински алгонкински',
    crs: 'француски (Сеселва креоли)',
    cs: 'чешки',
    csb: 'кашупски',
    csw: 'мочуришен кријски',
    cu: 'црковнословенски',
    cv: 'чувашки',
    cy: 'велшки',
    da: 'дански',
    dak: 'дакота',
    dar: 'даргва',
    dav: 'таита',
    de: 'германски',
    'de-AT': 'германски (Австрија)',
    'de-CH': 'германски (Швајцарија)',
    del: 'делавер',
    den: 'слејви',
    dgr: 'догрипски',
    din: 'динка',
    dje: 'зарма',
    doi: 'догри',
    dsb: 'долнолужички',
    dtp: 'дусунски',
    dua: 'дуала',
    dum: 'среднохоландски',
    dv: 'дивехи',
    dyo: 'јола-фоњи',
    dyu: 'џула',
    dz: 'ѕонгка',
    dzg: 'дазага',
    ebu: 'ембу',
    ee: 'еве',
    efi: 'ефик',
    egl: 'емилијански',
    egy: 'староегипетски',
    eka: 'екаџук',
    el: 'грчки',
    elx: 'еламски',
    en: 'англиски',
    'en-AU': 'англиски (Австралија)',
    'en-CA': 'англиски (Канада)',
    'en-GB': 'англиски (Обединето Кралство)',
    'en-US': 'англиски (Соединети Американски Држави)',
    enm: 'средноанглиски',
    eo: 'есперанто',
    es: 'шпански',
    'es-419': 'шпански (Латинска Америка)',
    'es-ES': 'шпански (Шпанија)',
    'es-MX': 'шпански (Мексико)',
    esu: 'централнојупички',
    et: 'естонски',
    eu: 'баскиски',
    ewo: 'евондо',
    ext: 'екстремадурски',
    fa: 'персиски',
    'fa-AF': 'персиски (Авганистан)',
    fan: 'фанг',
    fat: 'фанти',
    ff: 'фула',
    fi: 'фински',
    fil: 'филипински',
    fit: 'турнедаленски фински',
    fj: 'фиџиски',
    fo: 'фарски',
    fon: 'фон',
    fr: 'француски',
    'fr-CA': 'француски (Канада)',
    'fr-CH': 'француски (Швајцарија)',
    frc: 'каџунски француски',
    frm: 'среднофранцуски',
    fro: 'старофранцуски',
    frp: 'франкопровансалски',
    frr: 'севернофризиски',
    frs: 'источнофризиски',
    fur: 'фурлански',
    fy: 'западнофризиски',
    ga: 'ирски',
    gaa: 'га',
    gag: 'гагауски',
    gan: 'ган',
    gay: 'гајо',
    gba: 'гбаја',
    gbz: 'зороастриски дари',
    gd: 'шкотски гелски',
    gez: 'гиз',
    gil: 'гилбертански',
    gl: 'галисиски',
    glk: 'гилански',
    gmh: 'средногорногермански',
    gn: 'гварански',
    goh: 'старогорногермански',
    gon: 'гонди',
    gor: 'горонтало',
    got: 'готски',
    grb: 'гребо',
    grc: 'старогрчки',
    gsw: 'швајцарски германски',
    gu: 'гуџарати',
    guc: 'гвахиро',
    gur: 'фарефаре',
    guz: 'гуси',
    gv: 'манкс',
    gwi: 'гвичински',
    ha: 'хауса',
    hai: 'хајда',
    hak: 'хака',
    haw: 'хавајски',
    hax: 'јужен хајда',
    he: 'хебрејски',
    hi: 'хинди',
    hif: 'фиџиски хинди',
    hil: 'хилигајнонски',
    hit: 'хетитски',
    hmn: 'хмонг',
    ho: 'хири моту',
    hr: 'хрватски',
    hsb: 'горнолужички',
    hsn: 'сјанг',
    ht: 'хаитски',
    hu: 'унгарски',
    hup: 'хупа',
    hur: 'халкомелем',
    hy: 'ерменски',
    hz: 'хереро',
    ia: 'интерлингва',
    iba: 'ибан',
    ibb: 'ибибио',
    id: 'индонезиски',
    ie: 'интерлингве',
    ig: 'игбо',
    ii: 'сичуан ји',
    ik: 'инупијачки',
    ikt: 'западноканадски инуктитут',
    ilo: 'илокански',
    inh: 'ингушки',
    io: 'идо',
    is: 'исландски',
    it: 'италијански',
    iu: 'инуктитут',
    izh: 'ижорски',
    ja: 'јапонски',
    jam: 'јамајски креолски',
    jbo: 'ложбан',
    jgo: 'нгомба',
    jmc: 'мачаме',
    jpr: 'еврејскоперсиски',
    jrb: 'еврејскоарапски',
    jut: 'јитски',
    jv: 'јавански',
    ka: 'грузиски',
    kaa: 'каракалпачки',
    kab: 'кабилски',
    kac: 'качински',
    kaj: 'каџе',
    kam: 'камба',
    kaw: 'кави',
    kbd: 'кабардински',
    kbl: 'канембу',
    kcg: 'тјап',
    kde: 'маконде',
    kea: 'кабувердиану',
    ken: 'кењанг',
    kfo: 'коро',
    kg: 'конго',
    kgp: 'каинганшки',
    kha: 'каси',
    kho: 'хотански',
    khq: 'којра чиини',
    khw: 'коварски',
    ki: 'кикују',
    kiu: 'зазаки',
    kj: 'квањама',
    kk: 'казашки',
    kkj: 'како',
    kl: 'калалисут',
    kln: 'каленџин',
    km: 'кмерски',
    kmb: 'кимбунду',
    kn: 'каннада',
    ko: 'корејски',
    koi: 'коми-пермјачки',
    kok: 'конкани',
    kos: 'козрејски',
    kpe: 'кпеле',
    kr: 'канури',
    krc: 'карачаевско-балкарски',
    kri: 'крио',
    krj: 'кинарајски',
    krl: 'карелски',
    kru: 'курух',
    ks: 'кашмирски',
    ksb: 'шамбала',
    ksf: 'бафија',
    ksh: 'колоњски',
    ku: 'курдски',
    kum: 'кумички',
    kut: 'кутенајски',
    kv: 'коми',
    kw: 'корнски',
    kwk: 'кваквала',
    kxv: 'куви',
    ky: 'киргиски',
    la: 'латински',
    lad: 'ладино',
    lag: 'ланги',
    lah: 'ланда',
    lam: 'ламба',
    lb: 'луксембуршки',
    lez: 'лезгински',
    lfn: 'лингва франка нова',
    lg: 'ганда',
    li: 'лимбуршки',
    lij: 'лигурски',
    lil: 'лилуетски',
    liv: 'ливонски',
    lkt: 'лакотски',
    lmo: 'ломбардиски',
    ln: 'лингала',
    lo: 'лаошки',
    lol: 'монго',
    lou: 'луизијански креолски',
    loz: 'лози',
    lrc: 'севернолуриски',
    lsm: 'самиски',
    lt: 'литвански',
    ltg: 'латгалски',
    lu: 'луба-катанга',
    lua: 'луба-лулуа',
    lui: 'лујсењски',
    lun: 'лунда',
    luo: 'луо',
    lus: 'мизо',
    luy: 'луја',
    lv: 'латвиски',
    lzh: 'книжевен кинески',
    lzz: 'ласки',
    mad: 'мадурски',
    maf: 'мафа',
    mag: 'магахи',
    mai: 'маитили',
    mak: 'макасарски',
    man: 'мандинго',
    mas: 'масајски',
    mde: 'маба',
    mdf: 'мокшански',
    mdr: 'мандарски',
    men: 'менде',
    mer: 'меру',
    mfe: 'морисјен',
    mg: 'малгашки',
    mga: 'средноирски',
    mgh: 'макува-мито',
    mgo: 'мета',
    mh: 'маршалски',
    mi: 'маорски',
    mic: 'микмак',
    min: 'минангкабау',
    mk: 'македонски',
    ml: 'малајалски',
    mn: 'монголски',
    mnc: 'манџурски',
    mni: 'манипурски',
    moe: 'ину-аимунски',
    moh: 'мохавски',
    mos: 'моси',
    mr: 'марати',
    mrj: 'западномариски',
    ms: 'малајски',
    mt: 'малтешки',
    mua: 'мунданг',
    mul: 'повеќе јазици',
    mus: 'крик',
    mwl: 'мирандски',
    mwr: 'марвари',
    my: 'бурмански',
    mye: 'мјене',
    myv: 'ерзјански',
    mzn: 'мазендерански',
    na: 'науруански',
    nan: 'јужномински',
    nap: 'неаполски',
    naq: 'нама',
    nb: 'норвешки букмол',
    nd: 'северен ндебеле',
    nds: 'долногермански',
    'nds-NL': 'долногермански (Холандија)',
    ne: 'непалски',
    new: 'неварски',
    ng: 'ндонга',
    nia: 'нијас',
    niu: 'ниујески',
    njo: 'ао нага',
    nl: 'холандски',
    'nl-BE': 'холандски (Белгија)',
    nmg: 'квазио',
    nn: 'норвешки нинорск',
    nnh: 'нгиембун',
    no: 'норвешки',
    nog: 'ногајски',
    non: 'старонордиски',
    nov: 'новијал',
    nqo: 'нко',
    nr: 'јужен ндебеле',
    nso: 'северносотски',
    nus: 'нуер',
    nv: 'навахо',
    nwc: 'класичен неварски',
    ny: 'њанџа',
    nym: 'њамвези',
    nyn: 'њанколе',
    nyo: 'њоро',
    nzi: 'нзима',
    oc: 'окситански',
    oj: 'оџибва',
    ojb: 'северозападен оџибва',
    ojc: 'централен оџибва',
    ojs: 'очиски кријски',
    ojw: 'западен оџибва',
    oka: 'оканагански',
    om: 'оромо',
    or: 'одија',
    os: 'осетски',
    osa: 'осашки',
    ota: 'отомански турски',
    pa: 'пенџапски',
    pag: 'пангасинански',
    pal: 'средноперсиски',
    pam: 'пампанга',
    pap: 'папијаменто',
    pau: 'палауански',
    pcd: 'пикардски',
    pcm: 'нигериски пиџин',
    pdc: 'пенсилваниски германски',
    pdt: 'менонитски долногермански',
    peo: 'староперсиски',
    pfl: 'фалечкогермански',
    phn: 'феникиски',
    pi: 'пали',
    pis: 'пиџин',
    pl: 'полски',
    pms: 'пиемонтски',
    pnt: 'понтски',
    pon: 'понпејски',
    pqm: 'малиситски пасамакводски',
    prg: 'пруски',
    pro: 'старопровансалски',
    ps: 'паштунски',
    pt: 'португалски',
    'pt-BR': 'португалски (Бразил)',
    'pt-PT': 'португалски (Португалија)',
    qu: 'кечуански',
    quc: 'киче',
    qug: 'кичвански',
    raj: 'раџастански',
    rap: 'рапанујски',
    rar: 'раротонгански',
    rgn: 'ромањолски',
    rhg: 'рохиншки',
    rif: 'рифски',
    rm: 'реторомански',
    rn: 'рунди',
    ro: 'романски',
    'ro-MD': 'романски (Молдавија)',
    rof: 'ромбо',
    rom: 'ромски',
    rtm: 'ротумански',
    ru: 'руски',
    rue: 'русински',
    rug: 'ровијански',
    rup: 'влашки',
    rw: 'руандски',
    rwk: 'руа',
    sa: 'санскрит',
    sad: 'сандаве',
    sah: 'јакутски',
    sam: 'самарјански арамејски',
    saq: 'самбуру',
    sas: 'сасачки',
    sat: 'сантали',
    saz: 'саураштра',
    sba: 'нгембеј',
    sbp: 'сангу',
    sc: 'сардински',
    scn: 'сицилијански',
    sco: 'шкотски германски',
    sd: 'синди',
    sdc: 'сасарски сардински',
    sdh: 'јужнокурдски',
    se: 'северен сами',
    see: 'сенека',
    seh: 'сена',
    sei: 'сери',
    sel: 'селкупски',
    ses: 'којраборо сени',
    sg: 'санго',
    sga: 'староирски',
    sgs: 'самогитски',
    sh: 'српскохрватски',
    shi: 'тачелхит',
    shn: 'шан',
    shu: 'чадски арапски',
    si: 'синхалски',
    sid: 'сидамо',
    sk: 'словачки',
    sl: 'словенечки',
    slh: 'јужен лушуцид',
    sli: 'долношлезиски',
    sly: 'селајарски',
    sm: 'самоански',
    sma: 'јужен сами',
    smj: 'луле сами',
    smn: 'инариски сами',
    sms: 'сколт сами',
    sn: 'шона',
    snk: 'сонинке',
    so: 'сомалиски',
    sog: 'зогдијански',
    sq: 'албански',
    sr: 'српски',
    srn: 'срански тонго',
    srr: 'серер',
    ss: 'свати',
    ssy: 'сахо',
    st: 'сесото',
    stq: 'затерландски фризиски',
    str: 'салишки (Северен Теснец)',
    su: 'сундски',
    suk: 'сукума',
    sus: 'сусу',
    sux: 'сумерски',
    sv: 'шведски',
    sw: 'свахили',
    'sw-CD': 'свахили (Конго - Киншаса)',
    swb: 'коморијански',
    syc: 'класичен сириски',
    syr: 'сириски',
    szl: 'шлезиски',
    ta: 'тамилски',
    tce: 'јужнотучонски',
    tcy: 'тулу',
    te: 'телугу',
    tem: 'тимне',
    teo: 'тесо',
    ter: 'терено',
    tet: 'тетум',
    tg: 'таџикистански',
    tgx: 'тагишки',
    th: 'тајландски',
    tht: 'талтански',
    ti: 'тигриња',
    tig: 'тигре',
    tiv: 'тив',
    tk: 'туркменски',
    tkl: 'токелауански',
    tkr: 'цахурски',
    tl: 'тагалог',
    tlh: 'клингонски',
    tli: 'тлингит',
    tly: 'талишки',
    tmh: 'тамашек',
    tn: 'цвана',
    to: 'тонгајски',
    tog: 'њаса тонга',
    tok: 'токи пона',
    tpi: 'ток писин',
    tr: 'турски',
    tru: 'туројо',
    trv: 'тароко',
    ts: 'цонга',
    tsd: 'цаконски',
    tsi: 'цимшијански',
    tt: 'татарски',
    ttm: 'севернотучонски',
    ttt: 'татски',
    tum: 'тумбука',
    tvl: 'тувалуански',
    tw: 'тви',
    twq: 'тазавак',
    ty: 'тахитски',
    tyv: 'тувански',
    tzm: 'централноатлански тамазитски',
    udm: 'удмуртски',
    ug: 'ујгурски',
    uga: 'угаритски',
    uk: 'украински',
    umb: 'умбунду',
    und: 'непознат јазик',
    ur: 'урду',
    uz: 'узбечки',
    vai: 'вај',
    ve: 'венда',
    vec: 'венецијански',
    vep: 'вепшки',
    vi: 'виетнамски',
    vls: 'западнофламански',
    vmf: 'мајнскофранконски',
    vmw: 'макуа',
    vo: 'волапик',
    vot: 'вотски',
    vro: 'виру',
    vun: 'вунџо',
    wa: 'валонски',
    wae: 'валсер',
    wal: 'воламо',
    war: 'варајски',
    was: 'вашо',
    wbp: 'варлпири',
    wo: 'волофски',
    wuu: 'ву',
    xal: 'калмички',
    xh: 'коса',
    xmf: 'мегрелски',
    xnr: 'кангри',
    xog: 'сога',
    yao: 'јао',
    yap: 'јапски',
    yav: 'јенгбен',
    ybb: 'јемба',
    yi: 'јидиш',
    yo: 'јорупски',
    yrl: 'њенгату',
    yue: 'кантонски',
    za: 'џуаншки',
    zap: 'запотечки',
    zbl: 'блиссимболи',
    zea: 'зеландски',
    zen: 'зенага',
    zgh: 'стандарден марокански тамазитски',
    zh: 'кинески',
    'zh-Hans': 'поедноставен кинески',
    'zh-Hant': 'традиционален кинески',
    zu: 'зулу',
    zun: 'зуни',
    zxx: 'без лингвистичка содржина',
    zza: 'заза',
  },
  uz: {
    aa: 'afar',
    ab: 'abxaz',
    ace: 'achin',
    ada: 'adangme',
    ady: 'adigey',
    af: 'afrikaans',
    agq: 'agem',
    ain: 'aynu',
    ak: 'akan',
    ale: 'aleut',
    alt: 'janubiy oltoy',
    am: 'amxar',
    an: 'aragon',
    ann: 'obolo',
    anp: 'angika',
    ar: 'arab',
    'ar-001': 'arab (Dunyo)',
    arn: 'mapuche',
    arp: 'arapaxo',
    ars: 'najd arab',
    as: 'assam',
    asa: 'asu',
    ast: 'asturiy',
    atj: 'atikamek',
    av: 'avar',
    awa: 'avadxi',
    ay: 'aymara',
    az: 'ozarbayjon',
    ba: 'boshqird',
    ban: 'bali',
    bas: 'basa',
    be: 'belarus',
    bem: 'bemba',
    bez: 'bena',
    bg: 'bolgar',
    bgc: 'harianvi',
    bgn: 'g‘arbiy baluj',
    bho: 'bxojpuri',
    bi: 'bislama',
    bin: 'bini',
    bla: 'siksika',
    blo: 'Anii',
    bm: 'bambara',
    bn: 'bengal',
    bo: 'tibet',
    br: 'breton',
    brx: 'bodo',
    bs: 'bosniy',
    bug: 'bugi',
    byn: 'blin',
    ca: 'katalan',
    cay: 'kayuga',
    ccp: 'chakma',
    ce: 'chechen',
    ceb: 'sebuan',
    cgg: 'chiga',
    ch: 'chamorro',
    chk: 'chukot',
    chm: 'mari',
    cho: 'choktav',
    chp: 'chipevyan',
    chr: 'cheroki',
    chy: 'cheyenn',
    ckb: 'sorani-kurd',
    clc: 'chilkotin',
    co: 'korsikan',
    crg: 'michif',
    crj: 'janubi-sharqiy kri',
    crk: 'tekislik kri',
    crl: 'shomoli-sharqiy kri',
    crm: 'mus kri',
    crr: 'karolin algonkin',
    crs: 'kreol (Seyshel)',
    cs: 'chex',
    csw: 'botqoq kri',
    cu: 'slavyan (cherkov)',
    cv: 'chuvash',
    cy: 'valliy',
    da: 'dan',
    dak: 'dakota',
    dar: 'dargva',
    dav: 'taita',
    de: 'nemischa',
    'de-AT': 'nemischa (Avstriya)',
    'de-CH': 'nemischa (Shveytsariya)',
    dgr: 'dogrib',
    dje: 'zarma',
    doi: 'dogri',
    dsb: 'quyi sorb',
    dua: 'duala',
    dv: 'divexi',
    dyo: 'diola-fogni',
    dz: 'dzongka',
    dzg: 'dazag',
    ebu: 'embu',
    ee: 'eve',
    efi: 'efik',
    eka: 'ekajuk',
    el: 'grek',
    en: 'inglizcha',
    'en-AU': 'inglizcha (Avstraliya)',
    'en-CA': 'inglizcha (Kanada)',
    'en-GB': 'inglizcha (Buyuk Britaniya)',
    'en-US': 'inglizcha (Amerika Qo‘shma Shtatlari)',
    eo: 'esperanto',
    es: 'ispancha',
    'es-419': 'ispancha (Lotin Amerikasi)',
    'es-ES': 'ispancha (Ispaniya)',
    'es-MX': 'ispancha (Meksika)',
    et: 'estoncha',
    eu: 'bask',
    ewo: 'evondo',
    fa: 'fors',
    'fa-AF': 'fors (Afgʻoniston)',
    ff: 'fula',
    fi: 'fincha',
    fil: 'filipincha',
    fj: 'fiji',
    fo: 'farercha',
    fon: 'fon',
    fr: 'fransuzcha',
    'fr-CA': 'fransuzcha (Kanada)',
    'fr-CH': 'fransuzcha (Shveytsariya)',
    frc: 'kajun fransuz',
    frr: 'shimoliy friz',
    fur: 'friul',
    fy: 'g‘arbiy friz',
    ga: 'irland',
    gaa: 'ga',
    gag: 'gagauz',
    gan: 'gan',
    gd: 'shotland-gel',
    gez: 'geez',
    gil: 'gilbert',
    gl: 'galisiy',
    gn: 'guarani',
    gor: 'gorontalo',
    gsw: 'nemis (Shveytsariya)',
    gu: 'gujarot',
    guz: 'gusii',
    gv: 'men',
    gwi: 'gvichin',
    ha: 'xausa',
    hai: 'hayda',
    haw: 'gavaycha',
    hax: 'janubiy hayda',
    he: 'ivrit',
    hi: 'hind',
    hil: 'hiligaynon',
    hmn: 'xmong',
    hr: 'xorvat',
    hsb: 'yuqori sorb',
    ht: 'gaityan',
    hu: 'venger',
    hup: 'xupa',
    hur: 'halkomelem',
    hy: 'arman',
    hz: 'gerero',
    ia: 'interlingva',
    iba: 'iban',
    ibb: 'ibibio',
    id: 'indonez',
    ie: 'interlingve',
    ig: 'igbo',
    ii: 'sichuan',
    ikt: 'sharqiy-kanada inuktitut',
    ilo: 'iloko',
    inh: 'ingush',
    io: 'ido',
    is: 'island',
    it: 'italyan',
    iu: 'inuktitut',
    ja: 'yapon',
    jbo: 'lojban',
    jgo: 'ngomba',
    jmc: 'machame',
    jv: 'yavan',
    ka: 'gruzincha',
    kab: 'kabil',
    kac: 'kachin',
    kaj: 'kaji',
    kam: 'kamba',
    kbd: 'kabardin',
    kcg: 'tyap',
    kde: 'makonde',
    kea: 'kabuverdianu',
    kfo: 'koro',
    kgp: 'kaingang',
    kha: 'kxasi',
    khq: 'koyra-chiini',
    ki: 'kikuyu',
    kj: 'kvanyama',
    kk: 'qozoqcha',
    kkj: 'kako',
    kl: 'grenland',
    kln: 'kalenjin',
    km: 'xmer',
    kmb: 'kimbundu',
    kn: 'kannada',
    ko: 'koreyscha',
    koi: 'komi-permyak',
    kok: 'konkan',
    kpe: 'kpelle',
    kr: 'kanuri',
    krc: 'qorachoy-bolqor',
    krl: 'karel',
    kru: 'kurux',
    ks: 'kashmircha',
    ksb: 'shambala',
    ksf: 'bafiya',
    ksh: 'kyoln',
    ku: 'kurdcha',
    kum: 'qo‘miq',
    kv: 'komi',
    kw: 'korn',
    kwk: 'kvakvala',
    kxv: 'kuvi',
    ky: 'qirgʻizcha',
    la: 'lotincha',
    lad: 'ladino',
    lag: 'langi',
    lb: 'lyuksemburgcha',
    lez: 'lezgin',
    lg: 'ganda',
    li: 'limburg',
    lij: 'liguryan',
    lil: 'lilluet',
    lkt: 'lakota',
    lmo: 'lombard',
    ln: 'lingala',
    lo: 'laos',
    lou: 'luiziana kreol',
    loz: 'lozi',
    lrc: 'shimoliy luri',
    lsm: 'saamia',
    lt: 'litva',
    lu: 'luba-katanga',
    lua: 'luba-lulua',
    lun: 'lunda',
    lus: 'lushay',
    luy: 'luhya',
    lv: 'latishcha',
    mad: 'madur',
    mag: 'magahi',
    mai: 'maythili',
    mak: 'makasar',
    mas: 'masay',
    mdf: 'moksha',
    men: 'mende',
    mer: 'meru',
    mfe: 'morisyen',
    mg: 'malagasiy',
    mgh: 'maxuva-mitto',
    mgo: 'meta',
    mh: 'marshall',
    mi: 'maori',
    mic: 'mikmak',
    min: 'minangkabau',
    mk: 'makedon',
    ml: 'malayalam',
    mn: 'mongol',
    mni: 'manipur',
    moe: 'innu-aymun',
    moh: 'mohauk',
    mos: 'mossi',
    mr: 'maratxi',
    ms: 'malay',
    mt: 'maltiy',
    mua: 'mundang',
    mul: 'bir nechta til',
    mus: 'krik',
    mwl: 'miranda',
    my: 'birman',
    myv: 'erzya',
    mzn: 'mozandaron',
    na: 'nauru',
    nap: 'neapolitan',
    naq: 'nama',
    nb: 'norveg-bokmal',
    nd: 'shimoliy ndebele',
    nds: 'quyi nemis',
    'nds-NL': 'quyi nemis (Niderlandiya)',
    ne: 'nepal',
    new: 'nevar',
    ng: 'ndonga',
    nia: 'nias',
    niu: 'niue',
    nl: 'niderland',
    'nl-BE': 'niderland (Belgiya)',
    nmg: 'kvasio',
    nn: 'norveg-nyunorsk',
    nnh: 'ngiyembun',
    no: 'norveg',
    nog: 'no‘g‘ay',
    nqo: 'nko',
    nr: 'janubiy ndebel',
    nso: 'shimoliy soto',
    nus: 'nuer',
    nv: 'navaxo',
    ny: 'cheva',
    nyn: 'nyankole',
    oc: 'oksitan',
    ojb: 'shimoli-gʻarbiy ojibva',
    ojc: 'markaziy ijibve',
    ojs: 'oji-kri',
    ojw: 'gʻarbiy ojibva',
    oka: 'okanagan',
    om: 'oromo',
    or: 'oriya',
    os: 'osetin',
    pa: 'panjobcha',
    pag: 'pangasinan',
    pam: 'pampanga',
    pap: 'papiyamento',
    pau: 'palau',
    pcm: 'kreol (Nigeriya)',
    pis: 'pijin',
    pl: 'polyakcha',
    pqm: 'maliset-passamakvoddi',
    prg: 'pruss',
    ps: 'pushtu',
    pt: 'portugalcha',
    'pt-BR': 'portugalcha (Braziliya)',
    'pt-PT': 'portugalcha (Portugaliya)',
    qu: 'kechua',
    quc: 'kiche',
    raj: 'rajastani',
    rap: 'rapanui',
    rar: 'rarotongan',
    rhg: 'rohinja',
    rm: 'romansh',
    rn: 'rundi',
    ro: 'rumincha',
    'ro-MD': 'rumincha (Moldova)',
    rof: 'rombo',
    ru: 'ruscha',
    rup: 'arumin',
    rw: 'kinyaruanda',
    rwk: 'ruanda',
    sa: 'sanskrit',
    sad: 'sandave',
    sah: 'saxa',
    saq: 'samburu',
    sat: 'santali',
    sba: 'ngambay',
    sbp: 'sangu',
    sc: 'sardin',
    scn: 'sitsiliya',
    sco: 'shotland',
    sd: 'sindhi',
    sdh: 'janubiy kurd',
    se: 'shimoliy saam',
    seh: 'sena',
    ses: 'koyraboro-senni',
    sg: 'sango',
    shi: 'tashelxit',
    shn: 'shan',
    si: 'singal',
    sk: 'slovakcha',
    sl: 'slovencha',
    slh: 'janubiy lushutsid',
    sm: 'samoa',
    sma: 'janubiy saam',
    smj: 'lule-saam',
    smn: 'inari-saam',
    sms: 'skolt-saam',
    sn: 'shona',
    snk: 'soninke',
    so: 'somalicha',
    sq: 'alban',
    sr: 'serbcha',
    srn: 'sranan-tongo',
    ss: 'svati',
    ssy: 'saho',
    st: 'janubiy soto',
    str: 'streyts salish',
    su: 'sundan',
    suk: 'sukuma',
    sv: 'shved',
    sw: 'suaxili',
    'sw-CD': 'suaxili (Kongo – Kinshasa)',
    swb: 'qamar',
    syr: 'suryoniy',
    szl: 'silez',
    ta: 'tamil',
    tce: 'janubiy tutchone',
    te: 'telugu',
    tem: 'timne',
    teo: 'teso',
    tet: 'tetum',
    tg: 'tojik',
    tgx: 'tagish',
    th: 'tay',
    tht: 'taltan',
    ti: 'tigrinya',
    tig: 'tigre',
    tk: 'turkman',
    tlh: 'klingon',
    tli: 'tlingit',
    tn: 'tsvana',
    to: 'tongan',
    tok: 'tokipona',
    tpi: 'tok-piksin',
    tr: 'turk',
    trv: 'taroko',
    ts: 'tsonga',
    tt: 'tatar',
    ttm: 'shimoliy tutchone',
    tum: 'tumbuka',
    tvl: 'tuvalu',
    twq: 'tasavak',
    ty: 'taiti',
    tyv: 'tuva',
    tzm: 'markaziy atlas tamazigxt',
    udm: 'udmurt',
    ug: 'uyg‘ur',
    uk: 'ukrain',
    umb: 'umbundu',
    und: 'noma’lum til',
    ur: 'urdu',
    uz: 'o‘zbek',
    ve: 'venda',
    vec: 'venet',
    vi: 'vyetnam',
    vmw: 'makua',
    vo: 'volapyuk',
    vun: 'vunjo',
    wa: 'vallon',
    wae: 'valis',
    wal: 'volamo',
    war: 'varay',
    wbp: 'valbiri',
    wo: 'volof',
    wuu: 'vu xitoy',
    xal: 'qalmoq',
    xh: 'kxosa',
    xnr: 'kangri',
    xog: 'soga',
    yav: 'yangben',
    ybb: 'yemba',
    yi: 'idish',
    yo: 'yoruba',
    yrl: 'nyengatu',
    yue: 'kanton',
    za: 'Chjuan',
    zgh: 'tamazigxt',
    zh: 'xitoy',
    'zh-Hans': 'xitoy (soddalashgan)',
    'zh-Hant': 'xitoy (an’anaviy)',
    zu: 'zulu',
    zun: 'zuni',
    zxx: 'til tarkibi yo‘q',
    zza: 'zaza',
  },
  kk: {
    aa: 'афар тілі',
    ab: 'абхаз тілі',
    ace: 'ачех тілі',
    ada: 'адангме тілі',
    ady: 'адыгей тілі',
    af: 'африкаанс тілі',
    agq: 'агем тілі',
    ain: 'айну тілі',
    ak: 'акан тілі',
    ale: 'алеут тілі',
    alt: 'оңтүстік алтай тілі',
    am: 'амхар тілі',
    an: 'арагон тілі',
    ann: 'оболо тілі',
    anp: 'ангика тілі',
    ar: 'араб тілі',
    'ar-001': 'араб тілі (әлем)',
    arn: 'мапуче тілі',
    arp: 'арапахо тілі',
    ars: 'араб тілі (Неджда)',
    as: 'ассам тілі',
    asa: 'асу тілі',
    ast: 'астурия тілі',
    atj: 'атикамек тілі',
    av: 'авар тілі',
    awa: 'авадхи тілі',
    ay: 'аймара тілі',
    az: 'әзірбайжан тілі',
    ba: 'башқұрт тілі',
    ban: 'бали тілі',
    bas: 'баса тілі',
    be: 'беларусь тілі',
    bem: 'бемба тілі',
    bez: 'бена тілі',
    bg: 'болгар тілі',
    bgc: 'хариани тілі',
    bgn: 'батыс балучи тілі',
    bho: 'бходжпури тілі',
    bi: 'бислама тілі',
    bin: 'бини тілі',
    bla: 'сиксика тілі',
    blo: 'ании тілі',
    bm: 'бамбара тілі',
    bn: 'бенгал тілі',
    bo: 'тибет тілі',
    br: 'бретон тілі',
    brx: 'бодо тілі',
    bs: 'босния тілі',
    bug: 'бугис тілі',
    byn: 'блин тілі',
    ca: 'каталан тілі',
    cay: 'кайюга тілі',
    ccp: 'чакма тілі',
    ce: 'шешен тілі',
    ceb: 'себуано тілі',
    cgg: 'кига тілі',
    ch: 'чаморро тілі',
    chk: 'чуук тілі',
    chm: 'мари тілі',
    cho: 'чокто тілі',
    chp: 'чипевайан тілі',
    chr: 'чероки тілі',
    chy: 'шайен тілі',
    ckb: 'сорани тілі',
    clc: 'чилкотин тілі',
    co: 'корсика тілі',
    crg: 'мичиф тілі',
    crj: 'оңтүстік-шығыс кри тілі',
    crk: 'жазықтағы кри тілі',
    crl: 'солтүстік-шығыс кри тілі',
    crm: 'мус кри тілі',
    crr: 'каролиналық алгонкин тілі',
    crs: 'сейшельдік креол тілі',
    cs: 'чех тілі',
    csw: 'батпақты жердің кри тілі',
    cu: 'шіркеулік славян тілі',
    cv: 'чуваш тілі',
    cy: 'валлий тілі',
    da: 'дат тілі',
    dak: 'дакота тілі',
    dar: 'даргин тілі',
    dav: 'таита тілі',
    de: 'неміс тілі',
    'de-CH': 'неміс тілі (Швейцария)',
    dgr: 'догриб тілі',
    dje: 'зарма тілі',
    doi: 'догри тілі',
    dsb: 'төменгі лужица тілі',
    dua: 'дуала тілі',
    dv: 'дивехи тілі',
    dyo: 'диола тілі',
    dz: 'дзонг-кэ тілі',
    dzg: 'дазага тілі',
    ebu: 'эмбу тілі',
    ee: 'эве тілі',
    efi: 'эфик тілі',
    eka: 'экаджук тілі',
    el: 'грек тілі',
    en: 'ағылшын тілі',
    eo: 'эсперанто тілі',
    es: 'испан тілі',
    et: 'эстон тілі',
    eu: 'баск тілі',
    ewo: 'эвондо тілі',
    fa: 'парсы тілі',
    'fa-AF': 'парсы тілі (Ауғанстан)',
    ff: 'фула тілі',
    fi: 'фин тілі',
    fil: 'филиппин тілі',
    fj: 'фиджи тілі',
    fo: 'фарер тілі',
    fon: 'фон тілі',
    fr: 'француз тілі',
    frc: 'каджун тілі (француз)',
    frr: 'солтүстік фриз тілі',
    fur: 'фриуль тілі',
    fy: 'батыс фриз тілі',
    ga: 'ирланд тілі',
    gaa: 'га тілі',
    gag: 'гагауз тілі',
    gd: 'шотландиялық гэль тілі',
    gez: 'геэз тілі',
    gil: 'гильберт тілі',
    gl: 'галисия тілі',
    gn: 'гуарани тілі',
    gor: 'горонтало тілі',
    gsw: 'неміс тілі (Швейцария)',
    gu: 'гуджарати тілі',
    guz: 'гусии тілі',
    gv: 'мэн тілі',
    gwi: 'гвичин тілі',
    ha: 'хауса тілі',
    hai: 'хайда тілі',
    haw: 'гавайи тілі',
    hax: 'оңтүстік хайда тілі',
    he: 'иврит тілі',
    hi: 'хинди тілі',
    hil: 'хилигайнон тілі',
    hmn: 'хмонг тілі',
    hr: 'хорват тілі',
    hsb: 'жоғарғы лужица тілі',
    ht: 'гаити тілі',
    hu: 'венгр тілі',
    hup: 'хупа тілі',
    hur: 'халкомелем тілі',
    hy: 'армян тілі',
    hz: 'гереро тілі',
    ia: 'интерлингва тілі',
    iba: 'ибан тілі',
    ibb: 'ибибио тілі',
    id: 'индонезия тілі',
    ie: 'интерлингве тілі',
    ig: 'игбо тілі',
    ii: 'сычуан и тілі',
    ikt: 'батыс канадалық инуктитут тілі',
    ilo: 'илоко тілі',
    inh: 'ингуш тілі',
    io: 'идо тілі',
    is: 'исланд тілі',
    it: 'итальян тілі',
    iu: 'инуктитут тілі',
    ja: 'жапон тілі',
    jbo: 'ложбан тілі',
    jgo: 'нгомба тілі',
    jmc: 'мачаме тілі',
    jv: 'ява тілі',
    ka: 'грузин тілі',
    kab: 'кабил тілі',
    kac: 'качин тілі',
    kaj: 'каджи тілі',
    kam: 'камба тілі',
    kbd: 'кабардин тілі',
    kcg: 'тьяп тілі',
    kde: 'маконде тілі',
    kea: 'кабувердьяну тілі',
    kfo: 'коро тілі',
    kgp: 'кайнганг тілі',
    kha: 'кхаси тілі',
    khq: 'койра чини тілі',
    ki: 'кикуйю тілі',
    kj: 'кваньяма тілі',
    kk: 'қазақ тілі',
    kkj: 'како тілі',
    kl: 'калаалисут тілі',
    kln: 'каленжин тілі',
    km: 'кхмер тілі',
    kmb: 'кимбунду тілі',
    kn: 'каннада тілі',
    ko: 'корей тілі',
    koi: 'коми-пермяк тілі',
    kok: 'конкани тілі',
    kpe: 'кпелле тілі',
    kr: 'канури тілі',
    krc: 'қарашай-балқар тілі',
    krl: 'карель тілі',
    kru: 'курух тілі',
    ks: 'кашмир тілі',
    ksb: 'шамбала тілі',
    ksf: 'бафиа тілі',
    ksh: 'кёльн тілі',
    ku: 'күрд тілі',
    kum: 'құмық тілі',
    kv: 'коми тілі',
    kw: 'корн тілі',
    kwk: 'квакиутль тілі',
    kxv: 'куви тілі',
    ky: 'қырғыз тілі',
    la: 'латын тілі',
    lad: 'ладино тілі',
    lag: 'ланги тілі',
    lb: 'люксембург тілі',
    lez: 'лезгин тілі',
    lg: 'ганда тілі',
    li: 'лимбург тілі',
    lij: 'лигур тілі',
    lil: 'лиллуэт тілі',
    lkt: 'лакота тілі',
    lmo: 'ломбард тілі',
    ln: 'лингала тілі',
    lo: 'лаос тілі',
    lou: 'креоль тілі (Луизиана)',
    loz: 'лози тілі',
    lrc: 'солтүстік люри тілі',
    lsm: 'самия тілі',
    lt: 'литва тілі',
    lu: 'луба-катанга тілі',
    lua: 'луба-лулуа тілі',
    lun: 'лунда тілі',
    luo: 'луо тілі',
    lus: 'мизо тілі',
    luy: 'лухиа тілі',
    lv: 'латыш тілі',
    mad: 'мадур тілі',
    mag: 'магахи тілі',
    mai: 'майтхили тілі',
    mak: 'макасар тілі',
    mas: 'масай тілі',
    mdf: 'мокша тілі',
    men: 'менде тілі',
    mer: 'меру тілі',
    mfe: 'морисиен тілі',
    mg: 'малагаси тілі',
    mgh: 'макуа-меетто тілі',
    mgo: 'мета тілі',
    mh: 'маршалл тілі',
    mi: 'маори тілі',
    mic: 'микмак тілі',
    min: 'минангкабау тілі',
    mk: 'македон тілі',
    ml: 'малаялам тілі',
    mn: 'моңғол тілі',
    mni: 'манипури тілі',
    moe: 'инну-аймун тілі',
    moh: 'могавк тілі',
    mos: 'мосси тілі',
    mr: 'маратхи тілі',
    ms: 'малай тілі',
    mt: 'мальта тілі',
    mua: 'мунданг тілі',
    mul: 'бірнеше тіл',
    mus: 'крик тілі',
    mwl: 'миранд тілі',
    my: 'бирма тілі',
    myv: 'эрзя тілі',
    mzn: 'мазандеран тілі',
    na: 'науру тілі',
    nap: 'неаполитан тілі',
    naq: 'нама тілі',
    nb: 'норвегиялық букмол тілі',
    nd: 'солтүстік ндебеле тілі',
    nds: 'төменгі неміс тілі',
    'nds-NL': 'төменгі неміс тілі (Нидерланд)',
    ne: 'непал тілі',
    new: 'невар тілі',
    ng: 'ндонга тілі',
    nia: 'ниас тілі',
    niu: 'ниуэ тілі',
    nl: 'нидерланд тілі',
    'nl-BE': 'нидерланд тілі (Бельгия)',
    nmg: 'квасио тілі',
    nn: 'норвегиялық нюнорск тілі',
    nnh: 'нгиембун тілі',
    no: 'норвег тілі',
    nog: 'ноғай тілі',
    nqo: 'нко тілі',
    nr: 'оңтүстік ндебеле тілі',
    nso: 'солтүстік сото тілі',
    nus: 'нуэр тілі',
    nv: 'навахо тілі',
    ny: 'ньянджа тілі',
    nyn: 'нианколе тілі',
    oc: 'окситан тілі',
    ojb: 'солтүстік-батыс оджибве тілі',
    ojc: 'орталық оджибве тілі',
    ojs: 'оджи-кри тілі',
    ojw: 'батыс оджибве тілі',
    oka: 'оканаган тілі',
    om: 'оромо тілі',
    or: 'ория тілі',
    os: 'осетин тілі',
    osa: 'осейдж тілі',
    pa: 'пенджаб тілі',
    pag: 'пангасинан тілі',
    pam: 'пампанга тілі',
    pap: 'папьяменто тілі',
    pau: 'палау тілі',
    pcm: 'нигериялық пиджин тілі',
    pis: 'пиджин тілі',
    pl: 'поляк тілі',
    pqm: 'малесит-пассамакводди тілі',
    prg: 'пруссия тілі',
    ps: 'пушту тілі',
    pt: 'португал тілі',
    'pt-BR': 'португал тілі (Бразилия)',
    'pt-PT': 'португал тілі (Португалия)',
    qu: 'кечуа тілі',
    quc: 'киче тілі',
    raj: 'раджастани тілі',
    rap: 'рапануй тілі',
    rar: 'раротонган тілі',
    rhg: 'рохинджа',
    rm: 'романш тілі',
    rn: 'рунди тілі',
    ro: 'румын тілі',
    'ro-MD': 'румын тілі (Молдова)',
    rof: 'ромбо тілі',
    ru: 'орыс тілі',
    rup: 'арумын тілі',
    rw: 'киньяруанда тілі',
    rwk: 'руа тілі',
    sa: 'санскрит тілі',
    sad: 'сандаве тілі',
    sah: 'саха тілі',
    saq: 'самбуру тілі',
    sat: 'сантали тілі',
    sba: 'нгамбай тілі',
    sbp: 'сангу тілі',
    sc: 'сардин тілі',
    scn: 'сицилия тілі',
    sco: 'шотланд тілі',
    sd: 'синдхи тілі',
    sdh: 'оңтүстік күрд тілі',
    se: 'солтүстік саам тілі',
    seh: 'сена тілі',
    ses: 'койраборо сенни тілі',
    sg: 'санго тілі',
    sh: 'серб-хорват тілі',
    shi: 'ташелхит тілі',
    shn: 'шан тілі',
    si: 'сингал тілі',
    sk: 'словак тілі',
    sl: 'словен тілі',
    slh: 'оңтүстік лушуцид тілі',
    sm: 'самоа тілі',
    sma: 'оңтүстік саам тілі',
    smj: 'луле саам тілі',
    smn: 'инари саам тілі',
    sms: 'колтта саам тілі',
    sn: 'шона тілі',
    snk: 'сонинке тілі',
    so: 'сомали тілі',
    sq: 'албан тілі',
    sr: 'серб тілі',
    srn: 'сранан тонго тілі',
    ss: 'свати тілі',
    ssy: 'сахо тілі',
    st: 'оңтүстік сото тілі',
    str: 'солтүстік стрейтс тілі',
    su: 'сундан тілі',
    suk: 'сукума тілі',
    sv: 'швед тілі',
    sw: 'суахили тілі',
    'sw-CD': 'суахили тілі (Конго)',
    swb: 'комор тілі',
    syr: 'сирия тілі',
    szl: 'силез тілі',
    ta: 'тамил тілі',
    tce: 'оңтүстік тутчоне тілі',
    te: 'телугу тілі',
    tem: 'темне тілі',
    teo: 'тесо тілі',
    tet: 'тетум тілі',
    tg: 'тәжік тілі',
    tgx: 'тагиш тілі',
    th: 'тай тілі',
    tht: 'тальтан тілі',
    ti: 'тигринья тілі',
    tig: 'тигре тілі',
    tk: 'түрікмен тілі',
    tlh: 'клингон тілі',
    tli: 'тлинкит тілі',
    tn: 'тсвана тілі',
    to: 'тонган тілі',
    tok: 'токипона тілі',
    tpi: 'ток-писин тілі',
    tr: 'түрік тілі',
    trv: 'тароко тілі',
    ts: 'тсонга тілі',
    tt: 'татар тілі',
    ttm: 'солтүстік тутчоне тілі',
    tum: 'тумбука тілі',
    tvl: 'тувалу тілі',
    tw: 'тви тілі',
    twq: 'тасавак тілі',
    ty: 'таити тілі',
    tyv: 'тувин тілі',
    tzm: 'орталық атлас тамазигхт тілі',
    udm: 'удмурт тілі',
    ug: 'ұйғыр тілі',
    uk: 'украин тілі',
    umb: 'умбунду тілі',
    und: 'белгісіз тіл',
    ur: 'урду тілі',
    uz: 'өзбек тілі',
    vai: 'вай тілі',
    ve: 'венда тілі',
    vec: 'венеция тілі',
    vi: 'вьетнам тілі',
    vmw: 'макуа тілі',
    vo: 'волапюк тілі',
    vun: 'вунджо тілі',
    wa: 'валлон тілі',
    wae: 'вальзер тілі',
    wal: 'волайта тілі',
    war: 'варай тілі',
    wbp: 'вальбири тілі',
    wo: 'волоф тілі',
    wuu: 'қытай тілі (У)',
    xal: 'қалмақ тілі',
    xh: 'кхоса тілі',
    xnr: 'кангри тілі',
    xog: 'сога тілі',
    yav: 'янгбен тілі',
    ybb: 'йемба тілі',
    yi: 'идиш тілі',
    yo: 'йоруба тілі',
    yrl: 'ньенгату тілі',
    yue: 'кантон тілі',
    za: 'чжуан тілі',
    zgh: 'марокколық стандартты тамазигхт тілі',
    zh: 'қытай тілі',
    'zh-Hans': 'жеңілдетілген қытай тілі',
    'zh-Hant': 'дәстүрлі қытай тілі',
    zu: 'зулу тілі',
    zun: 'зуни тілі',
    zxx: 'тілдік мазмұны жоқ',
    zza: 'заза тілі',
  },
} as const
